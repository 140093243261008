const retireTools = {
  title: 'Retire Tools',
  warningMsg: [
    'You are about to retire tools. When a tool is retired, it is removed from normal service and placed into the Retired Tools List. If desired, the tool may be reinstated and placed back into normal service.',
    'It will no longer appear in the Tool Browser or on in-service tool reports.',
    'If the tool is later encountered during a transfer or if it is scanned, the tool may then be reinstated and placed back into normal service'
  ],
  labels: {
    0: 'Retirement Date:',
    1: 'Retired At',
    2: 'Responsible Party',
    3: 'Reason',
    4: 'You are about to retire {count} tool(s). Do you wish to continue?',
    5: 'Retirement Completed. 1 of 1 tools retired.',
    6: 'Status'
  },
  tableHeader: {
    desktop: [
      {
        label: 'Number',
        key: 'itemNumber',
        sortable: true
      },
      {
        label: 'Type',
        key: 'itemType',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        type: Number,
        isEditable: true
      },
      {
        label: 'Retired At',
        key: 'assignedToId',
        type: 'Guid',
        listTypeId: 10,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Responsible Party',
        key: 'responsiblePartyId',
        type: 'Guid',
        listTypeId: 7,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Cost Center',
        key: 'costCenterId',
        type: 'Guid',
        listTypeId: 3,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'costCodeId',
        type: 'Guid',
        listTypeId: 4,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Reason',
        key: 'reason',
        type: 'Guid',
        listTypeId: 40,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Status',
        key: 'itemStatusId',
        type: 'Guid',
        listTypeId: 15,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Notes',
        key: 'notes',
        isEditable: true,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Status',
        key: 'Status',
        type: String
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Number',
            key: 'itemNumber',
            sortable: true
          },
          {
            label: 'Type',
            key: 'itemType',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            type: Number,
            isEditable: true
          },
          {
            label: 'Retired At',
            key: 'assignedToId',
            type: 'Guid',
            listTypeId: 10,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'responsiblePartyId',
            type: 'Guid',
            listTypeId: 7,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Cost Center',
            key: 'costCenterId',
            type: 'Guid',
            listTypeId: 3,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'costCodeId',
            type: 'Guid',
            listTypeId: 4,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Reason',
            key: 'reason',
            type: 'Guid',
            listTypeId: 40,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Status',
            key: 'itemStatusId',
            type: 'Guid',
            listTypeId: 15,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Notes',
            key: 'notes',
            isEditable: true,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default retireTools
