export default {
  state: () => ({
    items: [],
    categoryItems: [],
    showEquipmentLeaseRentals: false
  }),
  mutations: {
    addItems (state, items) {
      state.items = [...items]
    },
    addcategoryItems (state, allItems) {
      state.categoryItems = [...allItems]
    },
    setShowEquipmentLeaseRentals (state, value) {
      state.showEquipmentLeaseRentals = value
    }
  },
  getters: {
    selectedItems (state) {
      return state.items
    },
    selectCategoryItems (state) {
      return state.categoryItems
    },
    isShowEquipmentLeaseRentals (state) {
      return state.showEquipmentLeaseRentals
    }
  }
}
