<template>
  <div
    class="action-menu d-flex align-items-center">
    <div class="tw-result-filter mb-2">
      <a class="addItem" v-if="actionButton" @click="actionButtonClick({actionName: actionButton.actionName})">+ {{ actionButton.label }}</a>
      <div class="filter-field tw-select-field mb-0">
        <select class="form-control" v-model="selectedAction" @change="selectAction()">
          <option selected :value="null" disabled>{{$t('requisition.return.ticket.actionLabels.0')}}</option>
          <template
            v-for="(action, index) in mergedActions"
          >
            <option
              :key="index"
              v-if="isActionShown(action)"
              :disabled="isActionDisabled(action)"
              :value="action"
              :class="{'disabled': isActionDisabled(action)}"
            >
              <template>
                {{action.title}}
              </template>
            </option>
          </template>
        </select>
      </div>
      <template v-if="isShowSelectedText">
        {{$tc('message.itemSelection', selectedLength)}}
      </template>
    </div>
  </div>
</template>
<script>
import { deepCopy } from '../../helpers/utils'
export default {
  name: 'TWTableActions',
  props: {
    selectedLength: {
      type: Number,
      required: true
    },
    pathToActions: {
      type: String,
      required: true
    },
    isActionShown: {
      type: Function,
      required: false,
      default: function () {
        return true
      }
    },
    isActionDisabled: {
      type: Function,
      required: false,
      default: function () {
        return false
      }
    },
    isShowSelectedText: {
      type: Boolean,
      required: false,
      default: true
    },
    actionButton: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectedAction: null
    }
  },
  methods: {
    selectAction () {
      if (this.selectedAction === null) return
      this.$emit('onActionSelected', this.selectedAction.actionName)
      this.$emit('onActionSelectedFull', this.selectedAction)
      this.selectedAction = null
    },
    actionButtonClick (clickedAction) {
      this.$emit('onActionSelected', clickedAction.actionName)
      this.$emit('onActionSelectedFull', clickedAction)
    }
  },
  computed: {
    mergedActions () {
      // Copy the English toolbar items so we don't modify them directly
      const mergedActions = deepCopy(this.englishActions)

      if (this.englishActions) {
        if (this.englishActions.length !== this.chosenLanguageActions.length) {
          throw new Error('English action list has different number of entries than current action list')
        }
      }

      // Iterate through each Object property, where each property is
      // a ToolbarMenu item
      Object.keys(this.englishActions).map((key) => {
        // Check if an override version of this Object property/menu item
        // exists for the currently selected language
        const override = this.chosenLanguageActions?.[key]

        // If an override exists, overwrite the English properties
        // with the override properties
        if (override) {
          Object.assign(mergedActions[key], override)
        }
      })

      // Return the Object properties as an array,
      // so we can iterate through them to create the toolbar items
      return mergedActions
    },
    englishActions () {
      return this.$t(this.pathToActions, 'en', {})
    },

    chosenLanguageActions () {
      return this.$t(this.pathToActions)
    }
  }
}
</script>
<style scoped>
li a.dropdown-item {
  overflow: auto;
}
li a.dropdown-item.disabled {
  opacity: 0.5;
}
li a:not(.disabled):hover {
  background-color: rgba(0, 0, 0, 0.1);
}
a.addItem {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}
</style>
