const locations = {
  tableHeader: {
    desktop: [
      {
        label: 'check',
        key: 'twEntityId',
        isModify: true
      },
      {
        label: 'Location No',
        key: 'entityNumber',
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        sortable: true
      },
      {
        label: 'City',
        key: 'city',
        sortable: true,
        isModify: false
      },
      {
        label: 'State',
        key: 'state',
        sortable: true,
        isModify: false
      },
      {
        label: 'Stock Location',
        key: 'stockpointName',
        sortable: true,
        isModify: false
      },
      {
        label: 'Status',
        key: 'inactive',
        sortable: true
      },
      {
        label: 'To Be Decommissioned',
        key: 'decommissionDate',
        sortable: true,
        isModify: false
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'twEntityId',
        isModify: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Location No',
            key: 'entityNumber',
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'City',
            key: 'city',
            sortable: true,
            isModify: false
          },
          {
            label: 'State',
            key: 'state',
            sortable: true,
            isModify: false
          },
          {
            label: 'Stock Location',
            key: 'stockpointId',
            sortable: true,
            isModify: false
          },
          {
            label: 'Status',
            key: 'inactive',
            sortable: true
          },
          {
            label: 'To Be Decommissioned',
            key: 'decommissionDate',
            sortable: true,
            isModify: false
          }
        ]
      },
      {
        label: '',
        key: '',
        isModify: false
      }
    ]
  }
}

export default locations
