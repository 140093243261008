<template>
  <div>
    <div class="dropdown hide-d">
      <a
        data-toggle="dropdown"
        href="#"
        class="dropdown-toggle"
      >
        <span class="block m-t-xs font-bold profile-name">
          <i class="fas fa-ellipsis-v"></i>
        </span>
      </a>
      <ul class="dropdown-menu animated fadeInRight m-t-xs">
        <li
          v-for="(action, index) in actionHeaders"
          :key="'action_' + index"
        >
          <a v-if="action[1].key ==='Print' && isPrintDisabled"
            class="dropdown-item"
            :class="{ 'isDisabled' : (isPrintDisabled) }"
            :title="$t('tickets.pick.subActionHeader.printTooltip')"
          >
            <i :class="action[1].icon"></i>
            {{action[1].title}}
          </a>
          <a v-else-if="action[1].key ==='collaborate' && isShowCollaboration && isDisabledCollaboration"
            class="dropdown-item"
            :class="{ 'isDisabled' : (isDisabledCollaboration) }"
          >
            <i :class="action[1].icon"></i>
            {{action[1].title}}
          </a>
          <a v-else-if="action[1].key ==='collaborate' && !isShowCollaboration"
            class="dropdown-item"
            :class="{ 'hide' : (true) }"
          >
            <i :class="action[1].icon"></i>
            {{action[1].title}}
          </a>
          <a v-else-if="action[1].key ==='collaborate' && isShowCollaboration
          && !isDisabledCollaboration" @click.prevent="selectAction(action, index)"
            class="dropdown-item"
          >
            <i :class="action[1].icon"></i>
            {{action[1].title + (collaborationCount > 0
                ? ' ('+ collaborationCount +')' : '')}}
          </a>
          <a v-else-if="action[1].key ==='DeleteCustomReport'"
          @click.prevent="selectAction(action, index)"
          >
           <div v-if='canDeleteCustomReports'>{{action[1].title}}</div>
          </a>
          <a v-else-if="action[1].title ==='Close Service Request' || action.title ==='Cerrar solicitud de servicio'" style="display: flex; align-items: center;"
            @click.prevent="selectAction(action, index)"
          >
            <img  src="../../assets/icons/check.png" style="width:15px; height: 15px"/> &nbsp;
            <div>{{action[1].title}}</div>
          </a>
          <a v-else
            @click.prevent="selectAction(action, index)"
            class="dropdown-item"
            :class="{ 'isDisabled' : (isDisabled && !action[1].isEditing ) && !(action[1].title === 'Back') }"
          >
            <i :class="action[1].icon"></i>
            {{action[1].title}}
          </a>
        </li>
      </ul>
    </div>
    <div class="row hide-m">
      <div class="col-lg-12">
        <div class="tw-result-tabs reports">
          <div class="tabs-holder">
            <div
              v-for="(action, index) in actionHeaders"
              :key="'action_' + index"
            >
              <a v-if="action[1].key ==='Print' && isPrintDisabled"
                :class="{ 'isDisabled' : (isPrintDisabled) }"
                :title="$t('tickets.pick.subActionHeader.printTooltip')"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title}}
              </a>
              <a v-else-if="action[1].title ==='Create Count Ticket' && !canAddCountTicket"
                :class="{ 'hide' : (true)}"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title}}
              </a>
              <a v-else-if="action[1].key ==='collaborate'
              && isShowCollaboration && isDisabledCollaboration"
                :class="{ 'isDisabled' : (isDisabledCollaboration)}"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title}}
                <span><i class="fas fa-lock"></i></span>
              </a>
              <a v-else-if="action[1].key ==='collaborate' && !isShowCollaboration"
                :class="{ 'hide' : (true)}"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title}}
              </a>
              <a v-else-if="action[1].key ==='collaborate' && isShowCollaboration
              && !isDisabledCollaboration" @click.prevent="selectAction(action, index)"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title + (collaborationCount > 0
                ? ' ('+ collaborationCount +')' : '')}}
              </a>
              <a v-else-if="action[1].key ==='DeleteCustomReport'"
              @click.prevent="selectAction(action, index)"
              >
              <div v-if='canDeleteCustomReports'>{{action[1].title}}</div>
              </a>
              <a v-else-if="action[1].title ==='Close Service Request' || action[1].title ==='Cerrar solicitud de servicio'" style="display: flex;" class="blur"
              @click.prevent="selectAction(action, index)"
              >
              <img  src="../../assets/icons/check.png" style="width:15px; height: 15px" /> &nbsp;&nbsp;
              <div>{{action[1].title}}</div>
              </a>
              <a v-else-if="(action[1].title ==='Add Task' ||
                action[1].title === 'Agregar tarea') && (!canTaskEdit && !canTaskAdd)"
                :class="{ 'isDisabled' : (!canTaskEdit && !canTaskAdd)}"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title}}
                <span>
                  <i class="fas fa-lock"
                    style="cursor: default;"
                    v-tooltip:bottom="$t('buttons.twButtonTooltip',
                    { buttonText: action[1].title, permission: ['TasksEdit','TasksAdd'] })"
                  ></i>
                </span>
               </a>
              <a v-else-if="(action[1].title === 'Save' ||
                action[1].title === 'Guardar')"
                @click.prevent="if (!isClosedWorkOrder) { selectAction(action, index) }"
                :class="{ 'isDisabled' : (isClosedWorkOrder) }"
                :disabled="isClosedWorkOrder"
                >
                  <i :class="action[1].icon"></i>
                  {{action[1].title}}
              </a>
              <a v-else-if="(action[1].title === 'Start Workflow' || action[1].title === 'Add Approval' ||
                action[1].title === 'Agregar aprobación' || action[1].title === 'Iniciar flujo de trabajo')"
                @click.prevent="if (isDisabledStartWorkflow) { selectAction(action, index) }"
                :class="{ 'isDisabled' : (!isDisabledStartWorkflow) }"
                :disabled="!isDisabledStartWorkflow"
                >
                  <i :class="action[1].icon"></i>
                  {{action[1].title}}
                <span v-if="(!isDisabledStartWorkflow && (action[1].title === 'Add Approval' ||
                  action[1].title === 'Agregar aprobación') ) ">
                  <i class="fas fa-lock"
                    style="cursor: default;"
                    v-tooltip:bottom="$t('buttons.twButtonTooltip',
                    { buttonText: action[1].title, permission: 'TasksEdit' })"
                  ></i>
                </span>
              </a>
              <a v-else-if="(action[1].title === 'Edit' || action[1].title === 'Editar')"
                @click.prevent="if (isEnableEditApprovalBtn) selectAction(action, index)"
                :class="{ 'isDisabled' : (!isEnableEditApprovalBtn) || isDisabled }"
                disabled="!isEnableEditApprovalBtn"
                :title="getDisabledHoverText(isDisabled, 'Edit', action[1].title === 'Edit' || action[1].title === 'Editar')"
                :style="{ cursor: (isDisabled && action[1].title === 'Edit') ? 'not-allowed' : 'pointer'}"
                >
                  <i :class="action[1].icon"></i>
                  {{action[1].title}}
              </a>
              <a v-else-if="(action[1].title === 'Delete' || action[1].title === 'Borrar')"
                @click.prevent="if (isEnableDeleteBtn) { selectAction(action, index) }"
                :class="{ 'isDisabled' : (!isEnableDeleteBtn) }"
                :disabled="!isEnableDeleteBtn"
                >
                  <i :class="action[1].icon"></i>
                  {{action[1].title}}
              </a>
              <a v-else
                @click.prevent="selectAction(action, index)"
                :class="{ 'isDisabled' : (isDisabled && !action[1].isEditing) && !(action[1].title === 'Back') }"
                :title="getDisabledHoverText(isDisabled, 'Export', action[1].title === 'Export' || action[1].title === 'Exportar')"
                :style="{ cursor: (isDisabled && action[1].title === 'Export') ? 'not-allowed' : 'pointer'}"
              >
                <i :class="action[1].icon"></i>
                {{action[1].title}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// THIS COMPONENT IS OBSOLETE. PLEASE USE ToolbarMenu/TWLinks INSTEAD.
// IF NO MORE USES OF THIS COMPONENT EXIST, DELETE IT
export default {
  name: 'ActionHeader',
  props: {
    actionHeaders: {
      type: Array,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabledCollaboration: {
      type: Boolean,
      required: false,
      default: false
    },
    isPrintDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowCollaboration: {
      type: Boolean,
      required: false,
      default: false
    },
    collaborationCount: {
      type: Number,
      required: false,
      default: 0
    },
    disableEditTaskbtn: {
      type: Boolean,
      required: false,
      default: false
    },
    isDisabledStartWorkflow: {
      type: Boolean,
      required: false,
      default: false
    },
    isEnableEditApprovalBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    isEnableDeleteBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    disabledHoverText: {
      type: String,
      required: false
    },
    isClosedWorkOrder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created () {
    if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
      document.documentElement.style.setProperty('--img-filter', 'invert(53%) sepia(58%) saturate(4401%) hue-rotate(88deg) brightness(107%) contrast(113%)')
    } else if (process.env.VUE_APP_BRAND === 'ALIGN') {
      document.documentElement.style.setProperty('--img-filter', 'invert(56%) sepia(44%) saturate(5731%) hue-rotate(204deg) brightness(97%) contrast(103%)')
    } else {
      document.documentElement.style.setProperty('--img-filter', 'invert(90%) sepia(28%) saturate(5931%) hue-rotate(338deg) brightness(91%) contrast(79%)')
    }
  },
  methods: {
    selectAction (action) {
      this.$emit('onSelectAction', action[0])
    },
    getDisabledHoverText (disabled, actionText, isAction) {
      if (disabled && actionText === 'Edit' && isAction === true) {
        return this.$t('report.customReports.disabledEditText')
      }
      if (disabled && actionText === 'Export' && isAction === true) {
        return this.$t('report.customReports.disabledExportText')
      }
    }
  },
  computed: {
    userPermissions () {
      return this.$store.getters.userPermission
    },
    canDeleteCustomReports () {
      return this.userPermissions.includes('CustomReportsDelete')
    },
    canTaskAdd () {
      return this.userPermissions.includes('TasksAdd')
    },
    canTaskEdit () {
      return this.userPermissions.includes('TasksEdit')
    },
    canAddCountTicket () {
      return this.userPermissions.includes('CountInventoryEdit')
    }
  }
}
</script>

<style scoped>
.tw-result-tabs.reports .tabs-holder a {
  font-size: 14px;
}
div.dropdown {
  position: absolute;
  top: 45px;
  right: 25px;
  font-size: 20px;
  z-index: 999;
}
ul.dropdown-menu {
  top: 30px!important;
  left: -232px!important;
  width: 250px;
  padding: 5px 0;
}
.dropdown a span {
  color: #647178;
  font-weight: 700;
}
.dropdown img {
  width: 22px;
}
li a.dropdown-item {
  overflow: auto;
}
.blur img{
  filter: var(--img-filter);
}
.blur:hover img{
  filter: none;
}

@media (min-width: 899px) {
  .hide-d {
    display: none!important;
  }
}
@media (max-width: 900px) {
  .hide-m {
    display: none;
  }
}
.hide {
  display: none !important;
}

</style>
