// Can set = true locally for debugging and development but otherwise should only be enabled in production
const ENABLE_INTERCOM = process.env.VUE_APP_INTERCOM_ENABLED
const APP_ID = 'apo8u161'
export function setupIntercom () { // Future attributes can be passed down to this function from state and set below in boot
  if (!ENABLE_INTERCOM) return
  loadIntercomScript()
  window.Intercom('boot', {
    app_id: APP_ID,
    hide_default_launcher: true
    // other attributes: https://developers.intercom.com/installing-intercom/web/attributes-objects/
  })
}
export function openIntercomWindow () {
  if (!ENABLE_INTERCOM) return
  const Intercom = window.Intercom
  if (!Intercom) return
  Intercom('show')
}
const loadIntercomScript = () => {
  const script = document.createElement('script')
  script.innerHTML = `
    var APP_ID = "${APP_ID}";
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  `
  document.body.appendChild(script)
}
