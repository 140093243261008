const authorization = {
  email: 'TW User Email',
  emailAlign: 'Align User Email',
  pwd: 'TW User Password',
  pwdAlign: 'Align User Password',
  msg: 'Invalid user or password.',
  title: 'Welcome to ToolWatch',
  titleTooLops: 'Welcome to ToolOps',
  titleAlign: 'Welcome to Align',
  login: 'Login',
  next: 'Next',
  rememberMe: 'Remember Me'
}

export default authorization
