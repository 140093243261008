const pickTicket = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'PickTicketId',
        type: 'check'
      },
      {
        label: 'Number',
        type: Number,
        key: 'Number',
        sortable: true
      },
      {
        label: 'Created',
        type: Date,
        key: 'CreatedOn',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'State',
        type: String,
        key: 'State',
        sortable: true
      },
      {
        label: 'Status',
        type: String,
        key: 'Status',
        sortable: true
      },
      {
        label: 'Source',
        type: String,
        key: 'OriginationType',
        sortable: true
      },
      {
        label: 'Pick From',
        key: 'PickFrom',
        sortable: true
      },
      {
        label: 'Pick For',
        type: String,
        key: 'PickFor',
        sortable: true
      },
      {
        label: 'Requested By',
        key: 'RequestedBy',
        sortable: true
      },
      {
        label: 'Due',
        type: Date,
        key: 'PickDueDate',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'Assigned To',
        type: String,
        key: 'AssignedTo',
        sortable: true
      },
      {
        label: 'Picked By',
        type: String,
        key: 'PickedBy',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'PickTicketId',
        type: 'check'
      },
      {
        label: 'Number',
        type: Number,
        key: 'Number',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Created',
            type: Date,
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'State',
            type: String,
            key: 'State',
            sortable: true
          },
          {
            label: 'Status',
            type: String,
            key: 'Status',
            sortable: true
          },
          {
            label: 'Source',
            type: String,
            key: 'OriginationType',
            sortable: true
          },
          {
            label: 'Pick From',
            key: 'PickFrom',
            sortable: true
          },
          {
            label: 'Pick For',
            type: String,
            key: 'PickFor',
            sortable: true
          },
          {
            label: 'Requested By',
            key: 'RequestedBy',
            sortable: true
          },
          {
            label: 'Due',
            type: Date,
            key: 'PickDueDate',
            sortable: true
          },
          {
            label: 'Assigned To',
            type: String,
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Picked By',
            type: String,
            key: 'PickedBy',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default pickTicket
