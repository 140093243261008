export default {
  state: () => ({
    showEhsNavigation: false,
    lastTWRoute: null,
    lastEHSRoute: null
  }),
  mutations: {
    setShowEhsNavigation (state, value) {
      state.showEhsNavigation = value
    },
    setLastTWRoute (state, value) {
      state.lastTWRoute = value
    },
    setLastEHSRoute (state, value) {
      state.lastEHSRoute = value
    }
  },
  getters: {
    isShowEhsNavigation (state) {
      return state.showEhsNavigation
    },
    lastTWRoute (state) {
      return state.lastTWRoute
    },
    lastEHSRoute (state) {
      return state.lastEHSRoute
    }
  }
}
