<template>
  <ul class="nav nav-tabs" role="tablist" v-if="isEquipmentService">
    <li>
    <a class="nav-link" :class="'active'" data-toggle="tab" :value="assetKind" >
      <span>{{$t('catalog.equipment')}}</span>
    </a>
    </li>
  </ul>
  <ul class="nav nav-tabs" role="tablist" v-else>
    <li v-if="isAll">
      <a class="nav-link" :class="assetKind === 'A' ? 'active' : ''" data-toggle="tab" :value="assetKind" @click="updateAssetKind('A')">
        <span>{{$t('catalog.allCategories')}}</span>
      </a>
    </li>
    <li v-if="isTemplate">
      <a class="nav-link" :class="assetKind === 'A' ? 'active' : ''" data-toggle="tab" :value="assetKind" @click="updateAssetKind('A')">
        <span>{{$t('catalog.templateCategories')}}</span>
      </a>
    </li>
    <li v-if="isTool">
      <a class="nav-link" :class="assetKind === 'T' ? 'active' : ''" data-toggle="tab" :value="assetKind" @click="updateAssetKind('T')">
        <span>{{$t('catalog.tools')}}</span>
      </a>
    </li>
    <li v-if="isMaterial">
      <a class="nav-link" :class="assetKind === 'M' ? 'active' : ''" data-toggle="tab" :value="assetKind" @click="updateAssetKind('M')">
        <span>{{$t('catalog.materials')}}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ReqAssetTypeSelector',
  props: {
    assetKind: {
      type: String,
      required: true
    },
    isAll: {
      type: Boolean,
      required: false,
      default: true
    },
    isTool: {
      type: Boolean,
      required: false,
      default: true
    },
    isMaterial: {
      type: Boolean,
      required: false,
      default: true
    },
    isTemplate: {
      type: Boolean,
      required: false,
      default: false
    },
    isEquipmentService: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  methods: {
    updateAssetKind (kind) {
      this.$store.commit('setAssetKind', kind)
      this.$emit('assetChange', kind)
    }
  }
}
</script>
<style scoped>
 .wrapper-content .tabs-container .nav li a {
   color: #7a7878!important;
 }
.sidebar-panel .tabs-container ul {
  display: flex;
  flex-direction: column;
}
.tw-category-selector .nav.nav-tabs li .nav-link {
  padding: 4px 6px 0px 0px;
  color: #7a7878!important;
}
.tw-category-selector .nav.nav-tabs li .nav-link.active,
.wrapper-content .tabs-container .nav li a.active {
  color:  var(--secondary-color)!important;
  border-bottom: none!important;
}
.tw-category-selector .nav.nav-tabs li .nav-link.active span,
.wrapper-content .tabs-container .nav li a.active span {
  border-bottom: 2px solid var(--secondary-color)!important;
}
</style>
