<template>
  <div>
    <h3>{{filterTitle}}</h3>
    <!-- <div class="search-field">
      <input type="text" name="s" v-model="searchText" @keyup.enter="onSearchFilter()">
      <button type="submit" @click="onSearchFilter()"><i class="fa fa-search"></i></button>
    </div> -->
    <ul class="items-holder" v-if="filterTitle === $t('catalog.category')">
      <li
      v-if="filterItems.length > 1">
        <div class="form-group checkbox-form filter">
        <input
        type="radio"
        id="'checkall_models'"
        :checked="isAllModelChecked"
        >
        <label :for="'checkall_models'" class="checkbox-holder" @click="updateCategory('isAllModel')">
        </label>
        <label :for="'checkall_models'">{{$t('catalog.allCategories')}}</label>
        </div>
      </li>
      <li
        v-for="(matchingItem, index) in filterItems"
        v-bind:key="matchingItem.Category">
        <div class="form-group checkbox-form filter">
          <input
            type="checkbox"
            :value="matchingItem.Category"
            v-model="categoryName"
            @change="updateCategory(filterTitle)"
            :id="filterTitle + '_' + index + '_' + matchingItem.Category"
          >
          <label :for="filterTitle + '_' + index + '_' + matchingItem.Category" class="checkbox-holder">
          </label>
          <label :for="filterTitle + '_' + index + '_' + matchingItem.Category">
            {{matchingItem.Category}} (<span>{{matchingItem.Count}}</span>)
          </label>
        </div>
      </li>
    </ul>
    <ul class="items-holder" v-else-if="filterTitle === $t('catalog.brand')">
      <li
      v-if="filterItems.length > 1">
        <div class="form-group checkbox-form filter">
          <input
          type="radio"
          id="'checkall_brands'"
          :checked="isAllBrandChecked"
          >
          <label  class="checkbox-holder" :for="'checkall_brands'" @click="updateCategory('isAllBrand')">
          </label>
          <label :for="'checkall_brands'">{{$t('catalog.allBrands')}}</label>
        </div>

      </li>
      <li
        v-for="(matchingItem, index) in filterItems"
        v-bind:key="matchingItem.Manufacturer">
        <div class="form-group checkbox-form filter">
          <input
            type="checkbox"
            :value="matchingItem.Manufacturer"
            v-model="manufacturerName"
            @change="updateCategory(filterTitle)"
            :id="filterTitle + '_' + index + '_' + (matchingItem.Manufacturer)"
          >
          <label :for="filterTitle + '_' + index + '_' + matchingItem.Manufacturer" class="checkbox-holder">
          </label>
          <label :for="filterTitle + '_' + index + '_' + (matchingItem.Manufacturer)">
            {{matchingItem.Manufacturer}} (<span>{{matchingItem.Count}}</span>)
          </label>
        </div>
      </li>
    </ul>
    <ul class="items-holder" v-else>
      <li
      v-if="filterItems.length > 1">
        <div class="form-group checkbox-form filter">
          <input
          type="radio"
          id="'checkall_template'"
          :checked="isAllTemplateChecked"
          >
          <label  class="checkbox-holder" :for="'checkall_templates'" @click="updateCategory('isAllTemplate')">
          </label>
          <label :for="'checkall_templates'">{{$t('catalog.allTemplates')}}</label>
        </div>

      </li>
      <li
        v-for="(matchingItem, index) in filterItems"
        v-bind:key="matchingItem.Template">
        <div class="form-group checkbox-form filter">
          <input
            type="checkbox"
            :value="matchingItem.Template"
            v-model="templateName"
            @change="updateCategory(filterTitle)"
            :id="filterTitle + '_' + index + '_' + (matchingItem.Template)"
          >
          <label :for="filterTitle + '_' + index + '_' + matchingItem.Template" class="checkbox-holder">
          </label>
          <label :for="filterTitle + '_' + index + '_' + (matchingItem.Template)">
            {{matchingItem.Template}} (<span>{{matchingItem.Count}}</span>)
          </label>
        </div>
      </li>
    </ul>
    <a v-if="filterItems.length === categorySeeMoreLength" href="#" class="see-more" data-toggle="modal" :data-target="'#' + filterTitle">
      {{$t('catalog.seeMore')}}
    </a>
  </div>
</template>

<script>
export default {
  name: 'RequisitionSearchFilterList',
  props: {
    filterTitle: {
      type: String,
      required: true
    },
    searchResults: {
      type: Object,
      default: null
    },
    categorySeeMoreLength: {
      type: Number,
      required: true
    },
    manufacturerSeeMoreLength: {
      type: Number,
      required: false
    },
    templateSeeMoreLength: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      searchText: '',
      categorySearchKey: '',
      brandSearchKey: '',
      categoryName: [],
      manufacturerName: [],
      isAllModelChecked: true,
      isAllBrandChecked: true,
      templateSearchKey: '',
      templateName: [],
      isAllTemplateChecked: true
    }
  },
  created () {
    if (this.searchCategoryList.length > 0 && this.filterTitle === this.$t('catalog.category')) {
      this.categoryName = this.searchCategoryList
      if (this.categoryName.length > 0) {
        this.isAllModelChecked = false
      }
    } else if (this.searchCategory != '' && this.filterTitle === this.$t('catalog.category')) {
      this.categoryName.push(this.searchCategory)
      if (this.categoryName.length > 0) {
        this.isAllModelChecked = false
      }
    }
    if (this.searchManufacturerList.length > 0 && this.filterTitle !== this.$t('catalog.category')) {
      this.manufacturerName = this.searchManufacturerList
      if (this.manufacturerName.length > 0) {
        this.isAllBrandChecked = false
      }
    } else if (this.searchManufacturer != '' && this.filterTitle !== this.$t('catalog.category')) {
      this.manufacturerName.push(this.searchManufacturer)
      if (this.manufacturerName.length > 0) {
        this.isAllBrandChecked = false
      }
    }
    if (this.searchTemplateList.length > 0 && this.filterTitle !== this.$t('catalog.category')) {
      this.templateName = this.searchTemplateList
      if (this.templateName.length > 0) {
        this.isAllTemplateChecked = false
      }
    } else if (this.searchTemplate != '' && this.filterTitle !== this.$t('catalog.category')) {
      this.templateName.push(this.searchTemplate)
      if (this.templateName.length > 0) {
        this.isAllTemplateChecked = false
      }
    }
  },
  methods: {
    onItemSelected: function (e) {
      this.$emit('filterSelected', e.target.value)
    },
    onSearchFilter () {
      if (this.filterTitle === this.$t('catalog.category')) {
        this.categorySearchKey = this.searchText
      } else {
        if (this.filterTitle === this.$t('catalog.brand')) {
          this.brandSearchKey = this.searchText
        } else {
          this.templateSearchKey = this.searchText
        }
      }
    },
    updateCategory (filterTitle) {
      if (filterTitle === 'isAllModel') {
        this.isAllModelChecked = true
        this.categoryName = []
        this.$emit('searchCategory', this.categoryName)
      } else if (filterTitle === 'isAllBrand') {
        this.isAllBrandChecked = true
        this.manufacturerName = []
        this.$emit('searchManufacturer', this.manufacturerName)
      } else if (filterTitle === 'isAllTemplate') {
        this.isAllTemplateChecked = true
        this.templateName = []
        this.$emit('searchTemplate', this.templateName)
      } else if (filterTitle === this.$t('catalog.category')) {
        if (this.categoryName.length > 0) {
          this.isAllModelChecked = false
        } else {
          this.isAllModelChecked = true
        }
        this.$emit('searchCategory', this.categoryName)
      } else if (filterTitle === this.$t('catalog.brand')) {
        if (this.manufacturerName.length > 0) {
          this.isAllBrandChecked = false
        } else {
          this.isAllBrandChecked = true
        }
        this.$emit('searchManufacturer', this.manufacturerName)
      } else {
        if (this.templateName.length > 0) {
          this.isAllTemplateChecked = false
        } else {
          this.isAllTemplateChecked = true
        }
        if (this.templateName.length > 1) {
          this.templateName.shift()
        }
        this.$emit('searchTemplate', this.templateName)
      }
    },
    resetCategoryAndManufacturer () {
      this.categoryName = []
      this.manufacturerName = []
      this.templateName = []
    }
  },
  computed: {
    filterItems () {
      if (this.searchResults) {
        if (this.filterTitle === this.$t('catalog.category')) {
          const categories_ = Array.from(this.searchResults.MatchingCategories).sort((a, b) => (a.Category ? a.Category : '').localeCompare(b.Category ? b.Category : ''))
          const categories = categories_.filter(category => category.Category && category.Category.toLowerCase().includes(this.categorySearchKey.toLowerCase()))
          if (this.isCategoryExpanded) {
            return categories
          } else {
            return categories.slice(0, this.categorySeeMoreLength)
          }
        } else {
          if (this.filterTitle === this.$t('catalog.brand')) {
            const manufacturers_ = Array.from(this.searchResults.MatchingManufacturers).sort((a, b) => (a.Manufacturer ? a.Manufacturer : '').localeCompare(b.Manufacturer ? b.Manufacturer : ''))
            const manufacturers = manufacturers_.filter(manufacturer => manufacturer.Manufacturer && manufacturer.Manufacturer.toLowerCase().includes(this.brandSearchKey.toLowerCase()))
            if (this.isManufacturerExpanded) {
              return manufacturers
            } else {
              return manufacturers.slice(0, this.manufacturerSeeMoreLength)
            }
          } else {
            const templates_ = Array.from(this.searchResults.MatchingTemplates).sort((a, b) => (a.Template ? a.Template : '').localeCompare(b.Template ? b.Template : ''))
            const templates = templates_.filter(template => template.Template && template.Template.toLowerCase().includes(this.brandSearchKey.toLowerCase()))
            if (this.isTemplateExpanded) {
              return templates
            } else {
              return templates.slice(0, this.templateSeeMoreLength)
            }
          }
        }
      }
      return []
    },
    searchCategoryList () {
      return this.$store.getters.searchCategoryList
    },
    searchManufacturerList () {
      return this.$store.getters.searchManufacturerList
    },
    searchCategory () {
      return this.$store.getters.searchCategory
    },
    searchManufacturer () {
      return this.$store.getters.searchManufacturer
    },
    searchTemplateList () {
      return this.$store.getters.searchTemplateList
    },
    searchTemplate () {
      return this.$store.getters.searchTemplate
    }
  },
  watch: {
    filterItems: function (newval) {
      this.dataItems = newval
    },
    searchCategory: function (value) {
      if (!value) return
      this.categoryName = []
      this.categoryName.push(value)
    },
    searchManufacturer: function (value) {
      if (!value) return
      this.manufacturerName = []
      this.manufacturerName.push(value)
    },
    searchCategoryList: function (value) {
      if (!value) return
      this.categoryName = value
      if (this.categoryName.length > 0) {
        this.isAllModelChecked = false
      } else {
        this.isAllModelChecked = true
      }
    },
    searchManufacturerList: function (value) {
      if (!value) return
      this.manufacturerName = value
      if (this.manufacturerName.length > 0) {
        this.isAllBrandChecked = false
      } else {
        this.isAllBrandChecked = true
      }
    },
    '$route.query.assetKind': function () {
      this.resetCategoryAndManufacturer()
    }
  }
}
</script>
<style scoped>
  .form-group.checkbox-form label {
    margin-left:0px!important;
  }
</style>
