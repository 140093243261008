<template>
  <div id="top-bar" class="d-flex flex-wrap-reverse justify-content-between">
    <div class="d-flex">
      <ul v-if="platformNavigation" class="nav">
        <li v-if="this.TWEnabled && this.isBrandAlign" title="Asset Management" :class="!this.$route.path.startsWith('/ehs') ? 'mm-active-url' : ''">
          <img src="../../assets/integration-logos/align-dozer.png" class="top-logo" @click="toggleNavigation($event, false)">
        </li>
        <li v-else-if="this.TWEnabled && !this.isBrandAlign" :class="!this.$route.path.startsWith('/ehs') ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, false)">TW</a>
        </li>
        <li v-else class="disabled">
          <a href="#" @click="disabledNavigation($event)">TW <i class="material-icons">lock</i></a>
        </li>
        <li v-if="this.EHSEnabled && this.isBrandAlign" title="EHS" :class="this.$route.path.startsWith('/ehs') ? 'mm-active-url' : ''">
          <img src="../../assets/integration-logos/align-helmit.png" class="top-logo" @click="toggleNavigation($event, true)">
        </li>
        <li v-else-if="this.EHSEnabled && !this.isBrandAlign" :class="this.$route.path.startsWith('/ehs') ? 'mm-active-url' : ''">
          <a href="#" @click="toggleNavigation($event, true)">EHS</a>
        </li>
        <li v-else class="disabled">
          <a href="#" @click="disabledNavigation($event)">EHS <i class="material-icons">lock</i></a>
        </li>
      </ul>
    </div>
    <div class="d-flex flex-row-reverse ml-auto" style="padding-left: 30px;">
      <a data-toggle="dropdown" class="dropdown-toggle">
        <span class="text-wrap ">{{ fullName }}</span>
        <img alt="" height="25" style="margin-right: 20px;" class="rounded-circle" src="../../assets/images/profile_small.jpg">
      </a>
      <div class="dropdown-menu">
        <div class="d-flex">
          <div class="p-3">
            <img src="../../assets/images/profile_small.jpg" alt="Image" class="rounded-circle">
          </div>
          <div class="p-3">
            <ul class="list-unstyled text-dark">
              <li>{{ fullName }}</li>
              <li>{{ userName }}</li>
              <li>
                <a href="/administration/user-preferences">{{ $t("buttons.settings") }}</a>
                <a class="ml-3" @click="logout($event)" href="#">{{ $t("requisition.editModal.logout") }}</a>
              </li>
            </ul>
            <LocaleSwitcher cssPosition="relative" />
          </div>
        </div>
      </div>
      <Notifications />
    </div>
  </div>
</template>
<script>
import LocaleSwitcher from '../shared/LocaleSwitcher'
import Notifications from '../../notifications/Notifications.vue'
import TWRoutes from '../../locales/en/platformNavigationRoutes.js'
import EHSRoutes from '../../locales/en/EHSNavigationRoutes.js'
import $ from 'jquery'
import 'floating-vue/dist/style.css'
import axios from 'axios'
import {
  errorHandler,
  clearLocalStorage,
  getLoginPath,
  routerEntitlement,
  getPlatformLoginUrl
} from '../../helpers/utils'
export default {
  name: 'ReqEditShipTo',
  components: {
    Notifications,
    LocaleSwitcher
  },
  data () {
    return {
      TWEnabled: false,
      EHSEnabled: false,
      isBrandAlign: false
    }
  },
  methods: {
    findEntitledRoutes (routes) {
      return routes.some(route => {
        if (!route.entitlement) {
          route.entitlement = true
        }
        if (routerEntitlement(route)) {
          return true
        }
        if (route.children) {
          return this.findEntitledRoutes(route.children, null)
        }
      })
    },
    async toggleNavigation (event, value) {
      event.preventDefault()
      this.$router.push({ path: value ? this.$store.getters.lastEHSRoute || '/ehs' : this.$store.getters.lastTWRoute || '/' }).catch((e) => e)
      this.$store.commit('setShowEhsNavigation', value)
      if (value) {
        $('#top-bar .nav li:nth-child(1)').removeClass('mm-active-url')
        $('#top-bar .nav li:nth-child(2)').addClass('mm-active-url')
      } else {
        $('#top-bar .nav li:nth-child(2)').removeClass('mm-active-url')
        $('#top-bar .nav li:nth-child(1)').addClass('mm-active-url')
      }
    },
    disabledNavigation (event) {
      event.preventDefault()
    },
    async logout (event) {
      try {
        const { status } = await axios.get('/api/logout')
        if (status === 200) {
          await this.toggleNavigation(event, true)
          setTimeout(() => {
            clearLocalStorage()
            const iframe = document.getElementById('safetyFrame')
            if (iframe) {
              iframe.src = this.baseUrl + '/admin/indexAdmin?login=1'
            }

            window.location.href = `${this.platformLoginUrl}/logout?callback_uri=${window.location.origin}&redirect_uri=${getLoginPath().replace('/', '')}`
          }, 200)
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  computed: {
    fullName () {
      return this.$store.getters.fullName
    },
    userName () {
      return this.$store.getters.userName
    },
    platformNavigation () {
      return this.$store.getters.showPlatformNavigation
    },
    baseUrl () {
      if (process.env.VUE_APP_SAFETY_URL === null || process.env.VUE_APP_SAFETY_URL === undefined) {
        return 'https://ehs.toolwatch.com'
      }
      return process.env.VUE_APP_SAFETY_URL
    },
    platformLoginUrl () {
      return getPlatformLoginUrl()
    }
  },
  mounted () {
    this.$store.commit('setShowEhsNavigation', this.$route.path.startsWith('/ehs'))
    const TWRoutesClone = structuredClone(TWRoutes)
    const EHSRoutesClone = structuredClone(EHSRoutes)
    this.TWEnabled = this.findEntitledRoutes(TWRoutesClone)
    this.EHSEnabled = true
    $('.dropdown-menu').on('click', function (e) {
      e.stopPropagation()
    })
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
      if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show')
      }
      $(this).next('.dropdown-menu').toggleClass('show')
    })
    if (process.env.VUE_APP_BRAND === 'ALIGN') {
      this.isBrandAlign = true
      document.getElementById('top-bar').style.backgroundColor = '#20333c'
    } else {
      document.getElementById('top-bar').style.backgroundColor = '#4f555e'
    }
  }
}
</script>
<style scoped>
#top-bar {
  padding-left: 265px;
  min-height: 44px;
  position: fixed;
  width: 100%;
  z-index: 101;
  min-width: 300px;
}
body.mini-navbar #top-bar {
  padding-left: 130px;
}
body.body-small #top-bar {
  padding-left: 80px;
}
#top-bar .dropdown-toggle {
  line-height: 40px;
  z-index: 1;
}
#top-bar .dropdown-toggle span {
  color: white;
  font-weight: bold;
  margin-right: 10px
}
#top-bar .list-unstyled {
  border-bottom: 1px solid gray;
}
#top-bar .dropdown-menu li {
  margin-bottom: 5px;
  padding-right: 10px;
}
#top-bar .dropdown-menu li:last-child {
  padding: 5px 0;
}
#top-bar .nav > li {
  border-top: 4px solid #4f555e;
  box-sizing: border-box;
}
#top-bar .nav > li.mm-active-url {
  border-top: 4px solid var(--secondary-color);
  border-left: none;
}
#top-bar .nav > li a {
  font-size: 16px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}
#top-bar .nav > li a > i {
  vertical-align: middle;
  font-size: 16px;
}
#top-bar .nav > li.disabled a {
  cursor: inherit;
  color: white;
  opacity: 0.5;
}
.top-logo {
  border: 4.5px solid transparent;
  cursor: pointer;
  width: 42px;
  height: 42px;
  margin: 2px 8px;
}
</style>
