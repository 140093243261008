const reqbyReq = {
  tableHeader: {
    desktop: [
      {
        label: 'Status',
        key: 'Status',
        sortable: 'true'
      },
      {
        label: 'Date',
        key: 'CreatedOn',
        sortable: 'true'
      },
      {
        label: 'Request',
        key: 'Number',
        sortable: 'true'
      },
      {
        label: 'By',
        key: 'RequestedByName',
        sortable: 'true'
      },
      {
        label: 'For',
        key: 'DeliverToEntityName',
        sortable: 'true'
      },
      {
        label: 'Cost Center',
        key: 'CostCenterName',
        sortable: 'true'
      },
      {
        label: 'Cost Code',
        key: 'CostCodeName',
        sortable: 'true'
      },
      {
        label: 'Items',
        key: 'QtyRequested',
        sortable: 'true'
      }
    ],
    mobile: [
      {
        label: 'Status',
        key: 'Status'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            type: String,
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Barcode',
            key: 'Barcode',
            sortable: true
          },
          {
            label: 'Serial Number',
            key: 'SerialNumber',
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'ResponsibleParty',
            sortable: true
          },
          {
            label: 'Owner',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'On Site',
            key: 'Quantity',
            type: Number,
            sortable: true
          },
          {
            label: 'Available To Return',
            key: 'AvailableQuantity',
            type: Number,
            sortable: true
          },
          {
            label: 'Pending Return',
            key: 'Returning',
            type: Number,
            sortable: true
          },
          {
            label: 'To Return',
            key: 'QuantityReturned',
            type: Number,
            isEditable: true,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default reqbyReq
