<template>
  <div class="toggle-switch-base" v-on:click="toggle" :style="styleObject">
    <input :checked="checked" type="checkbox">
    <span class="toggle-switch-slider round"></span>
  </div>
</template>
<script>
export default {
  name: 'ToggleSwitch',
  data () {
    return {
      styleObject: {
        '--height': this.height,
        '--width': this.width,
        '--active-color': this.activeColor,
        '--inactive-color': this.inactiveColor
      },
      checked: this.isActive
    }
  },
  props: {
    height: {
      type: String,
      default: '24px'
    },
    width: {
      type: String,
      default: '45px'
    },
    activeColor: {
      type: String,
      default: '#D09433'
    },
    inactiveColor: {
      type: String,
      default: '#C0C0C0'
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    toggle () {
      this.checked = !this.checked
      this.$emit('onActiveChanged', this.checked)
    }
  },
  watch: {
    isActive (value) {
      this.checked = value
    }
  }
}
</script>
