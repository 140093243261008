export default {
  state: () => ({
    isShowOnlyTracked: true
  }),
  mutations: {
    setIsShowOnlyTracked (state, flag) {
      state.isShowOnlyTracked = flag
    }
  },
  getters: {
    isShowOnlyTracked (state) {
      return state.isShowOnlyTracked
    },
    catalogSearchType (state) {
      return state.isShowOnlyTracked ? 1 : 2
    }
  }
}
