const pickLists = {
  tableHeader: {
    desktop: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Requested',
        key: 'RequiredQty',
        type: Number,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Picked',
        key: 'PickQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Manufacturer',
        key: 'Manuf',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        type: String,
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        type: String,
        sortable: true
      },
      {
        label: 'Generic',
        key: 'IsGeneric',
        isEditable: true,
        type: Boolean,
        sortable: true
      },
      {
        label: 'Pick From',
        key: 'PickFromId',
        type: 'Guid',
        listType: 10,
        sortable: true
      },
      {
        label: 'Bin',
        key: 'Bin'
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ],
    mobile: [
      {
        label: 'T',
        key: 'ItemType'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Requested',
            key: 'RequiredQty',
            type: Number,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Picked',
            key: 'PickQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'Manufacturer',
            key: 'Manuf',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            type: String,
            sortable: true
          },
          {
            label: 'Generic',
            key: 'IsGeneric',
            isEditable: true,
            type: Boolean,
            sortable: true
          },
          {
            label: 'Pick From',
            key: 'PickFromId',
            type: 'Guid',
            listType: 10,
            sortable: true
          },
          {
            label: 'Bin',
            key: 'Bin'
          }
        ]
      },
      {
        label: '',
        key: '',
        type: 'Action'
      }
    ]
  }
}

export default pickLists
