<template>
  <modal-dialog
    ref="dlgRef"
    class="tw-shipping-modal"
    :title="$t('retiredTools.history.title')"
  >
    <template v-slot:body>
      <TWTable
        :data="tableData"
        :pathToHeader="'retiredTools.history.tableHeader'"
        :rowComponentData="rowComponentData"
        @onSortBy="onSortBy"
      ></TWTable>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn"
        @click="close"
      >
        {{$t('buttons.ok')}}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/shared/ModalDialog.vue'
import { sortingFunc } from '../../helpers/utils'
import { useWorkOrderRowsComponentData, createDefaultWorkOrderRowsProps } from '../../components/gridRows/workOrderRows/workOrderComponentData'
import TWTable from '../shared/TWTable.vue'

export default {
  name: 'RetiredToolHistory',
  components: {
    ModalDialog,
    TWTable
  },
  props: {
    data: {
      type: Array
    }
  },
  data () {
    return {
      sortData: {
        key: 'TransactionDate',
        asc: true,
        type: Date
      }
    }
  },
  setup () {
    const rowProps = createDefaultWorkOrderRowsProps()
    const rowComponentData = useWorkOrderRowsComponentData(rowProps)
    return { rowComponentData }
  },
  methods: {
    open () {
      this.$refs.dlgRef.open()
    },
    close () {
      this.$refs.dlgRef.close()
    },
    onSortBy (data) {
      this.sortData = data
    }
  },
  computed: {
    totalItems () {
      return this.data.length
    },
    tableData () {
      const tblData = [...this.data]
      sortingFunc(tblData, this.sortData)
      return tblData
    }
  }
}
</script>
