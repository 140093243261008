const requisitionBrowserView = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestItemId',
        type: 'check'
      },
      {
        label: 'Category',
        type: String,
        key: 'Category',
        sortable: true
      },
      {
        label: 'Model',
        type: String,
        key: 'ModelNumber',
        sortable: true
      },
      {
        label: 'Description',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Date Needed',
        key: 'RequestedDueDate',
        sortable: true
      },
      {
        label: 'Request Qty',
        key: 'RequestedQty',
        sortable: true
      },
      {
        label: 'Undecided Qty',
        key: 'UndecidedQty',
        sortable: true
      },
      {
        label: 'Actioned Qty',
        key: 'ActionedQty',
        sortable: true
      },
      {
        label: 'Pick Qty',
        key: 'PickQty',
        sortable: true
      },
      {
        label: 'Purchase Qty',
        key: 'PurchaseQty',
        sortable: true
      },
      {
        label: 'Rejected Qty',
        key: 'RejectedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            type: String,
            key: 'Category',
            sortable: true
          },
          {
            label: 'Model',
            type: String,
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Description',
            type: String,
            key: 'Description',
            sortable: true
          },
          {
            label: 'Date Needed',
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Request Qty',
            key: 'RequestedQty',
            sortable: true
          },
          {
            label: 'Undecided Qty',
            key: 'UndecidedQty',
            sortable: true
          },
          {
            label: 'Actioned Qty',
            key: 'ActionedQty',
            sortable: true
          },
          {
            label: 'Pick Qty',
            key: 'PickQty',
            sortable: true
          },
          {
            label: 'Purchase Qty',
            key: 'PurchaseQty',
            sortable: true
          },
          {
            label: 'Rejected Qty',
            key: 'RejectedQty',
            sortable: true
          }
        ]
      }
    ]
  }
}
export default requisitionBrowserView
