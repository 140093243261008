const service = {
  requests: {
    title: 'Service Request Browser',
    equipmentTitle: 'Equipment Service Request',
    actionHeader: {
      0: {
        title: 'Create Service Request',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Convert to Work Order',
        icon: 'fas fa-file-export'
      },
      2: {
        title: 'Close Service Request'
      },
      3: {
        title: 'Attachments',
        icon: 'fas fa-paperclip'
      }
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'serviceRequestId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'number',
          sortable: 'true'
        },
        {
          label: 'Urgency',
          key: 'priority',
          sortable: 'true'
        },
        {
          label: 'Request',
          key: 'createdOn',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'State',
          key: 'state',
          sortable: 'true'
        },
        {
          label: 'Status',
          key: 'status',
          sortable: 'true'
        },
        {
          label: 'Item No.',
          key: 'itemNumber',
          sortable: 'true'
        },
        {
          label: 'Item Description',
          key: 'itemDescription',
          sortable: 'true'
        },
        {
          label: 'Serial Number',
          key: 'serialNumber',
          sortable: 'true'
        },
        {
          label: 'Requested Service',
          key: 'description',
          sortable: 'true'
        },
        {
          label: 'Note',
          key: 'note',
          sortable: 'true'
        },
        {
          label: 'Manufacturer',
          key: 'manufacturer',
          sortable: 'true'
        },
        {
          label: 'Model',
          key: 'model',
          sortable: 'true'
        },
        {
          label: 'Requested By',
          key: 'requestedBy',
          sortable: 'true'
        },
        {
          label: '',
          key: ''
        }
      ],
      mobile: [
        {
          label: '',
          key: 'serviceRequestId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'No.',
              key: 'number',
              sortable: 'true'
            },
            {
              label: 'Urgency',
              key: 'priority',
              sortable: 'true'
            },
            {
              label: 'Request',
              key: 'createdOn',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'State',
              key: 'state',
              sortable: 'true'
            },
            {
              label: 'Status',
              key: 'status',
              sortable: 'true'
            },
            {
              label: 'Item No.',
              key: 'itemNumber',
              sortable: 'true'
            },
            {
              label: 'Item Description',
              key: 'itemDescription',
              sortable: 'true'
            },
            {
              label: 'Serial Number',
              key: 'serialNumber',
              sortable: 'true'
            },
            {
              label: 'Requested Service',
              key: 'description',
              sortable: 'true'
            },
            {
              label: 'Note',
              key: 'note',
              sortable: 'true'
            },
            {
              label: 'Manufacturer',
              key: 'manufacturer',
              sortable: 'true'
            },
            {
              label: 'Model',
              key: 'model',
              sortable: 'true'
            },
            {
              label: 'Requested By',
              key: 'requestedBy',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    requiringBrowseTableHeader: {
      desktop: [
        {
          label: 'Category',
          key: 'category',
          sortable: 'true'
        },
        {
          label: 'Number',
          key: 'itemNumber',
          sortable: 'true'
        },
        {
          label: 'Bar Code',
          key: 'barcode',
          sortable: 'true'
        },
        {
          label: 'Serial Number',
          key: 'serialNumber',
          sortable: 'true'
        },
        {
          label: 'Kit',
          key: 'kitNumber',
          sortable: 'true'
        },
        {
          label: 'T',
          key: 'itemType',
          sortable: 'true'
        },
        {
          label: 'Description',
          key: 'description',
          sortable: 'true'
        },
        {
          label: 'Manufacturer',
          key: 'manufacturer',
          sortable: 'true'
        },
        {
          label: 'Model',
          key: 'model',
          sortable: 'true'
        },
        {
          label: 'Qty',
          key: 'quantity',
          sortable: 'true'
        },
        {
          label: 'Uom',
          key: 'uom',
          sortable: 'true'
        },
        {
          label: 'Status',
          key: 'itemStatus',
          sortable: 'true'
        },
        {
          label: 'PI',
          key: 'personalIssue',
          sortable: 'true'
        },
        {
          label: 'Assignment',
          key: 'assignedTo',
          sortable: 'true'
        },
        {
          label: 'Age',
          key: 'ageDays',
          sortable: 'true'
        },
        {
          label: 'Owner',
          key: 'owner',
          sortable: 'true'
        },
        {
          label: 'Manager',
          key: 'manager',
          sortable: 'true'
        },
        {
          label: 'Department',
          key: 'department',
          sortable: 'true'
        },
        {
          label: 'Supervisor',
          key: 'supervisor',
          sortable: 'true'
        },
        {
          label: 'Item Class',
          key: 'className',
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Category',
              key: 'category',
              sortable: 'true'
            },
            {
              label: 'Number',
              key: 'itemNumber',
              sortable: 'true'
            },
            {
              label: 'Bar Code',
              key: 'barcode',
              sortable: 'true'
            },
            {
              label: 'Serial Number',
              key: 'serialNumber',
              sortable: 'true'
            },
            {
              label: 'Kit',
              key: 'kitNumber',
              sortable: 'true'
            },
            {
              label: 'T',
              key: 'itemType',
              sortable: 'true'
            },
            {
              label: 'Description',
              key: 'description',
              sortable: 'true'
            },
            {
              label: 'Manufacturer',
              key: 'manufacturer',
              sortable: 'true'
            },
            {
              label: 'Model',
              key: 'model',
              sortable: 'true'
            },
            {
              label: 'Qty',
              key: 'quantity',
              sortable: 'true'
            },
            {
              label: 'Uom',
              key: 'uom',
              sortable: 'true'
            },
            {
              label: 'Status',
              key: 'itemStatus',
              sortable: 'true'
            },
            {
              label: 'PI',
              key: 'personalIssue',
              sortable: 'true'
            },
            {
              label: 'Assignment',
              key: 'assignedTo',
              sortable: 'true'
            },
            {
              label: 'Age',
              key: 'ageDays',
              sortable: 'true'
            },
            {
              label: 'Owner',
              key: 'owner',
              sortable: 'true'
            },
            {
              label: 'Manager',
              key: 'manager',
              sortable: 'true'
            },
            {
              label: 'Department',
              key: 'department',
              sortable: 'true'
            },
            {
              label: 'Supervisor',
              key: 'supervisor',
              sortable: 'true'
            },
            {
              label: 'Item Class',
              key: 'className',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    searchContainer: {
      open: 'Open',
      closed: 'Closed',
      all: 'All',
      status: 'Status',
      requestedBy: 'Requested By',
      serviceRequested: 'Service Requested',
      requestedDate: 'Request Date',
      to: 'to'
    },
    serviceRequest: {
      urgency: [
        'Low',
        'Normal',
        'High'
      ],
      serviceRequest: 'Service Request',
      requestedBy: 'Requested by',
      requestedOn: 'Requested on',
      status: 'Status',
      itemRequiringService: 'Item Requiring Service',
      serviceRequestOrProblem: 'Service Request or Problem',
      ugency: 'Urgency',
      note: 'Note',
      serviceRequestNumber: 'Service Request Number',
      browserServiceRequests: 'Browse Service Requests',
      serviceDescription: 'Service Description'
    },
    toolBrowser: {
      title: 'Tool Browser'
    },
    dlgMessages: {
      delete: 'Are you sure you want to delete the selected service request?',
      addItem: 'You can only associate exactly one item with a service request.',
      cantDeleteSRMsg: 'You cannot delete a service request once it has been converted to a work order.'
    },
    dlgMessagesClose: {
      close: 'Are you sure you want to close the service request(s)?',
      success: 'Service request(s) have been closed.',
      allWorkOrderSelect: 'You cannot close service request(s) once they have been converted to a work order. Update your selection',
      workOrderWithserviceRequest: 'There are selected service request(s) with work orders. You cannot close a service request that has a work order. Update your selection.',
      closeServiceRequest: 'You cannot close service request(s) once they are already closed. Update your selection'
    },
    convertMsg: 'This service request has already been converted.',
    browserLineActions: [
      {
        title: 'View',
        actionName: 'editCountTicket',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Close',
        actionName: 'closeserviceRequest',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Delete',
        actionName: 'deleteCountTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  workOrder: {
    title: 'Work Orders Browser',
    equipmentTitle: 'Equipment Work Order Browser',
    subTitle: 'Work Order',
    filterTitle: 'Browser Work Orders',
    actionHeader: {
      0: {
        title: 'Create Work Order',
        icon: 'fas fa-plus'
      }
    },
    subActionHeader: {
      0: {
        title: 'Save',
        icon: 'fas fa-save'
      },
      1: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      2: {
        title: 'Attachments',
        icon: 'fas fa-paperclip'
      }
    },
    filter: {
      0: 'Service Request',
      1: 'Created Date',
      2: 'to'
    },
    edit: {
      save: {
        title: {
          0: 'Update Tool Record',
          1: 'Close work order?'
        },
        labels: {
          0: 'Meter',
          1: 'Current Meter',
          2: 'New Meter',
          3: 'Difference',
          4: 'Status',
          5: 'Current Status',
          6: 'New Status',
          7: 'Item Number'
        },
        msg: {
          0: 'By setting the completion date in the past, this may cause the generation of a past due work order, if you are using a service plan for this item. Are you sure you want to continue?',
          1: 'The completion date as well as time has not been set. If closed, this work order will not show up in any reports that expect a valid completion date. Are you sure you want to continue?',
          2: 'This Work Order has not been saved. Do you want to Save the Work Order so that it can be printed?',
          3: 'Date & Time required'
        }
      },
      detail: {
        title: 'Work Order Details',
        summary: {
          title: 'Work Order Summary',
          serviceEventTitle: 'Service Event Summary',
          labels: {
            0: 'Requested Service',
            1: 'Request Notes',
            2: 'PO Number',
            3: 'View Service Request...',
            4: 'Recurrence'
          }
        },
        schedule: {
          title: 'Schedule',
          labels: {
            0: 'Assigned To',
            1: 'Urgency',
            2: 'Due Date'
          }
        },
        completionDetails: {
          title: 'Completion Details',
          labels: {
            0: 'Completion Date',
            1: 'Meter at Completion',
            2: 'Completed by',
            3: 'Note'
          }
        }
      },
      tasks: {
        title: 'Tasks',
        required: {
          title: 'Required',
          labels: {
            0: 'Work Order Tasks',
            1: 'Service Event Tasks'
          }
        },
        history: {
          title: 'History',
          labels: {
            0: 'Work Performed'
          }
        },
        detail: {
          label: {
            0: 'Task Detail',
            1: 'Task No.',
            2: 'Task Type',
            3: 'Description',
            4: 'Calibration Task',
            5: 'Pass / Fail Value',
            6: 'Pass / Fail',
            7: 'Pass',
            8: 'Fail',
            9: 'Standard',
            10: 'Unit',
            11: 'As Found',
            12: 'As Left',
            13: 'Completion Information',
            14: 'Performed By',
            15: 'Date / Time',
            16: 'Est. Hrs',
            17: 'Actual Hrs.',
            18: 'Note',
            19: 'Charges',
            20: 'Cost Center',
            21: 'Cost Code',
            22: 'Service Class'
          }
        },
        warning: {
          title: 'Mark as Not Done?',
          msg: 'This task has already been marked as \'Done\'. Would you like to mark this task as NOT \'Done\' and proceed?',
          addErrorMsg: 'Please add in a valid Item ID in the work order to add a task'
        }
      },
      instructions: {
        title: 'Instructions'
      },
      requiredTools: {
        title: 'Required Tools',
        addModels: {
          0: 'Required Tools',
          1: 'Required Tools for Work Order',
          2: 'Tool Model Browser'
        },
        warning: {
          title: 'Confirm Tool Delete'
        }
      },
      parts: {
        title: 'Parts',
        labels: {
          0: 'Parts and Supplies',
          1: 'Use Parts'
        },
        addParts: {
          0: 'Required Parts',
          1: 'Required Parts for Work Order',
          2: 'Material Inventory',
          3: 'Used',
          4: 'Actual Quantity',
          5: 'Just repair parts for this asset',
          6: 'All'
        },
        warning: {
          msg: 'Please select an item to service first'
        }
      },
      charges: {
        title: 'Charges',
        labels: {
          0: 'Work Order Charges',
          1: 'Work Order Defaults',
          2: 'Cost Center',
          3: 'Cost Code',
          4: 'Service Class',
          5: 'Total Hrs.',
          6: 'Total Parts $',
          7: 'Total Labor $',
          8: 'Total Other $',
          9: 'Total Charges $',
          10: 'Charge Description',
          11: 'Qty.',
          12: 'Cost',
          13: 'Unit',
          14: 'Charge',
          15: 'Additional Charge',
          16: 'Service Event Charges',
          17: 'Service Event Defaults'
        },
        warning: {
          title: 'Confirm Charge Delete',
          msg: 'Are you sure you want to delete the selected row(s)? Click \'Yes\' to delete or \'No\' to cancel.'
        }
      },
      assignments: {
        title: 'Assignments',
        labels: {
          0: 'Assignment History',
          1: 'Reassign This Item...'
        },
        errorMsg: 'You can not perform this function until the work order has been created (saved at least once).'
      }
    }
  },
  schedule: {
    title: 'Browse Service Schedule',
    filterTitle: 'Service Schedule',
    actionHeader: {
      0: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      1: {
        title: 'Export Data',
        icon: 'fas fa-file-export'
      }
    },
    lineAction: {
      0: {
        title: 'View Work Order'
      },
      1: {
        title: 'View Item'
      },
      2: {
        title: 'Explain'
      }
    },
    orderColumns: [
      {
        key: 'AssignedTo',
        value: 'Assigned To'
      },
      {
        key: 'Barcode',
        value: 'Barcode'
      },
      {
        key: 'Description',
        value: 'Description'
      },
      {
        key: 'ItemNumber',
        value: 'Item Number'
      },
      {
        key: 'Manufacturer',
        value: 'Manufacturer'
      },
      {
        key: 'Model',
        value: 'Model'
      }
    ],
    filter: {
      0: 'Open',
      1: 'Scheduled',
      2: 'All',
      3: 'Next Date',
      4: 'to',
      5: 'Current Assignment',
      6: 'Item Number',
      7: 'Model',
      8: 'Work Order',
      9: 'Sort'
    },
    explanation: {
      title: 'Service Event Explanation'
    }
  }
}

export default service
