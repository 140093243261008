import { deepCopy, getFormatedDateString, getTransferDetailObj, setTransferHeader, uniq } from '../helpers/utils'
import axios from 'axios'

export default {
  state: () => ({
    transfers: [],
    transferTicketHeader: {},
    transferTicketNumber: null,
    pickTicketNumber: null,
    transferTicketSearchParam: {
      fromNumber: '',
      toNumber: '',
      toId: null,
      fromId: null,
      from: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
      to: `${getFormatedDateString(new Date())}T24:00:00`,
      includeDeleted: false
    },
    isKeepPageOpen: false
  }),
  mutations: {
    addTransfer (state, item) {
      const array = [...state.transfers, ...item]
      state.transfers = uniq(array, x => {
        if (!x.FromKey.KitId && !x.FromKey.isItemBrowser) {
          return x.FromKey.ItemId
        }
        return x.Id
      })
    },
    addAllToTransfer (state, item) {
      state.transfers = [...state.transfers, ...item]
    },
    resetTransfer (state) {
      state.transfers = []
    },
    setTransferTicketHeader (state, header) {
      state.transferTicketHeader = { ...header }
    },
    setPickTicketNumber (state, number) {
      state.pickTicketNumber = number
    },
    setTransferTicketNumber (state, number) {
      state.transferTicketNumber = number
    },
    removeTransferTicketItem (state, { Id: id, ItemType: itemType, ItemNumber: itemNumber }) {
      if (itemType !== 'K') {
        state.transfers = deepCopy(state.transfers.filter(ele => ele.Id != id))
      } else {
        state.transfers = deepCopy(state.transfers.filter(ele => ele.Id != id && ele.FromKey.KitNumber !== itemNumber))
      }
    },
    setTransferTicketSearchParam (state, value) {
      state.transferTicketSearchParam = value
    },
    setIsKeepPageOpen (state, isOpen) {
      state.isKeepPageOpen = isOpen
    }
  },
  actions: {
    async addKitContents (context, { transfersData, kitIds, transferHeader, isFromPickTicket }) {
      const reqArray = []
      kitIds.forEach(kitId => {
        const params = {
          orgId: `"${localStorage.getItem('OrgId')}"`,
          kitId: `"${kitId}"`,
          kitAssignmentId: 'null',
          itemId: 'null'
        }
        reqArray.push(axios.post('/api/core/KitsService/GetKitContents', params))
      })
      try {
        const data = await Promise.all(reqArray)
        const kitsArray = data.reduce((res, list) => {
          res.push(list.data.Body)
          return res
        }, [])
        kitsArray.forEach(kit => {
          if (kit.length) {
            const idx = transfersData.findIndex(item => item.ItemNumber == kit[0].kitNumber)
            const transferItems = []
            kit.forEach(item => {
              transferItems.push(getTransferDetailObj(item, transferHeader))
            })
            transfersData.splice(idx + 1, 0, transferItems)
          }
        })
        if (isFromPickTicket) {
          context.commit('addAllToTransfer', transfersData.flat())
        } else {
          context.commit('addTransfer', transfersData.flat())
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getTransferHeader (context, obj) {
      try {
        context.commit('setTransferTicketHeader', {
          ...setTransferHeader({
            ...obj
          })
        })
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  getters: {
    transfers (state) {
      return state.transfers
    },
    transferTicketHeader (state) {
      return state.transferTicketHeader
    },
    transferTicketNumber (state) {
      return state.transferTicketNumber
    },
    pickTicketNumber (state) {
      return state.pickTicketNumber
    },
    getTransferTicketSearchParam (state) {
      return state.transferTicketSearchParam
    },
    isKeepPageOpen (state) {
      return state.isKeepPageOpen
    }
  }
}
