const transferTicket = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'TransferId',
        type: 'check'
      },
      {
        label: 'Transfer Date',
        type: Date,
        key: 'CreatedOn',
        sortable: true,
        sorted: true,
        asc: true
      },
      {
        label: 'Assignment Date',
        type: Date,
        key: 'AssignmentDate',
        sortable: true
      },
      {
        label: 'Status',
        type: String,
        key: 'IsDraft',
        sortable: true
      },
      {
        label: 'Number',
        type: Number,
        key: 'TransferNumber',
        sortable: true
      },
      {
        label: 'Transferred From',
        type: String,
        key: 'TwEntityFrom',
        sortable: true
      },
      {
        label: 'Transferred To',
        type: String,
        key: 'TwEntityTo',
        sortable: true
      },
      {
        label: 'Source',
        type: String,
        key: 'OriginationType',
        sortable: true
      },
      {
        label: 'Transferred By',
        type: String,
        key: 'AssignedBy',
        sortable: true
      },
      {
        label: 'Delivered By',
        type: String,
        key: 'DeliveredBy',
        sortable: true
      },
      {
        label: 'Document No.',
        type: String,
        key: 'DocNumber',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'TransferId',
        type: 'check'
      },
      {
        label: 'Number',
        type: Number,
        key: 'TransferNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Transfer Date',
            type: Date,
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'Assignment Date',
            type: Date,
            key: 'AssignmentDate',
            sortable: true
          },
          {
            label: 'Transferred From',
            type: String,
            key: 'TwEntityFrom',
            sortable: true
          },
          {
            label: 'Transferred To',
            type: String,
            key: 'TwEntityTo',
            sortable: true
          },
          {
            label: 'Source',
            type: String,
            key: 'OriginationType',
            sortable: true
          },
          {
            label: 'Transferred By',
            type: String,
            key: 'AssignedBy',
            sortable: true
          },
          {
            label: 'Delivered By',
            type: String,
            key: 'DeliveredBy',
            sortable: true
          },
          {
            label: 'Document No.',
            type: String,
            key: 'DocNumber',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default transferTicket
