export default {
  state: () => ({
    loading: false,
    initialLoading: false,
    asyncPercent: 0,
    asyncStateId: null,
    asyncStatus: 'Running Job',
    resourcesLoading: 0
  }),
  mutations: {
    setLoading (state, flag) {
      state.loading = flag
    },
    setLoadingCount (state, flag) {
      if (flag === true) {
        state.resourcesLoading++
      } else {
        state.resourcesLoading--
        if (state.resourcesLoading < 0) {
          state.resourcesLoading = 0
        }
      }
    },
    setInitialLoading (state, flag) {
      state.initialLoading = flag
    },
    setAsyncPercent (state, value) {
      state.asyncPercent = value
    },
    setAsyncStateId (state, value) {
      state.asyncStateId = value
    },
    setAsyncStatus (state, value) {
      state.asyncStatus = value
    }
  },
  actions: {

  },
  getters: {
    loading (state) {
      return state.loading
    },
    loadingCount (state) {
      return state.resourcesLoading
    },
    initialLoading (state) {
      return state.initialLoading
    },
    asyncPercent (state) {
      return state.asyncPercent
    },
    asyncStateId (state) {
      return state.asyncStateId
    },
    asyncStatus (state) {
      return state.asyncStatus
    }
  }
}
