import { isLeapYear } from './utils'

export const TXT_MIN_LENGTH = 0
export const TXT_MAX_LENGTH = Infinity
export const TRANSFER_TICKET_REPORT_ID = 'BC84237A-D15D-448B-B734-F0511D695089'
export const PICK_TICKET_REPORT_ID = '27FEC09E-F805-4358-B461-FC8B4B25E652'
export const COUNT_SHEET_REPORT_ID = 'd617fb81-526d-4ec9-a3f5-62da151f6050'
export const COUNT_TICKET_REPORT_ID = 'DE686FCC-F566-4BE4-BF17-B3335A33FECB'
export const INVENTORY_ADJUSTMENT_REPORT_ID = '80174512-2008-4A48-B5D8-9FBFADDD2D6A'
export const TEMPLATE_DETAIL_REPORT_ID = '651EF3C3-76B7-4814-8464-5EE90344038E'
export const SERVICE_SCHEDULE_REPORT_ID = 'C5BC2B2A-F60B-4061-8885-51985A905B39'
export const WORKORDER_REPORT_ID = '79B113AF-26BF-4F77-9F02-60F527C7EC78'
export const PURCHASE_ORDER_REPORT_ID = '6996ae39-46e9-48d5-9dba-55292db49095'
export const RECEIVING_TICKET_REPORT_ID = '40f1c8d5-17ee-4c2c-abed-d072e878fa9b'
export const CONSUMABLE_DETAIL_REPORT_ID = '3572c410-3a39-4f2c-9d7f-d5814b1ce8b3'
export const INVOICE_REPORT_ID = '6E0330BF-804C-4378-B2E8-F41E1A5A6B6D'
export const INVOICE_WITH_RATES_REPORT_ID = '25E0796A-B68B-41C1-956A-E08288D29344'
export const TASKS_REPORT_ID = '05686B15-7608-464C-8CC7-96367E887C4C'
export const WORKFLOW_BUILDER_REPORT_ID = 'C5BC2B2A-F60B-4061-8885-51985A905B38'
export const WORKFLOW_BROWSER_REPORT_ID = 'A4C4341C-E2FD-4413-9FF2-FFC7D7D4F584'
export const catalogPath = '/catalog/detail/'

export const ACTION_HEADER_TYPE = {
  PRINT: 'print',
  ADD_TEMPLATE: 'addTemplate',
  ADD_TOOL_RECORD: 'addToolRecord',
  ADD_MATERIAL_RECORD: 'addMaterialRecord',
  ATTACHMENTS: 'attachments',
  ADD_ITEM: 'addItem',
  CHECK_ALL: 'checkAll',
  UNCHECK_ALL: 'unCheckAll',
  SAVE: 'save',
  DELETE_ITEM: 'deleteItem',
  CLEAR_PO_DRAFT: 'clearPODraft'
}
const SUNDAY = 1
const MONDAY = 2
const TUESDAY = 4
const WEDNESDAY = 8
const THURSDAY = 16
const FRIDAY = 32
const SATURDAY = 64
const WEEKENDDAY = 65
const WEEKDAY = 62
const ALLDAYS = 127

const STATE = [
  {
    label: '',
    value: 0
  },
  {
    label: 'Alabama',
    value: 1
  },
  {
    label: 'Alaska',
    value: 2
  },
  {
    label: 'Arizona',
    value: 3
  },
  {
    label: 'Arkansas',
    value: 4
  },
  {
    label: 'California',
    value: 5
  },
  {
    label: 'Colorado',
    value: 6
  },
  {
    label: 'Connecticut',
    value: 7
  },
  {
    label: 'Delaware',
    value: 8
  },
  {
    label: 'Florida',
    value: 9
  },
  {
    label: 'Georgia',
    value: 10
  },
  {
    label: 'Hawaii',
    value: 11
  },
  {
    label: 'Idaho',
    value: 12
  },
  {
    label: 'Illinois',
    value: 13
  },
  {
    label: 'Indiana',
    value: 14
  },
  {
    label: 'Iowa',
    value: 15
  },
  {
    label: 'Kansas',
    value: 16
  },
  {
    label: 'Kentucky',
    value: 17
  },
  {
    label: 'Louisiana',
    value: 18
  },
  {
    label: 'Maine',
    value: 19
  },
  {
    label: 'Maryland',
    value: 20
  },
  {
    label: 'Massachusetts',
    value: 21
  },
  {
    label: 'Michigan',
    value: 22
  },
  {
    label: 'Minnesota',
    value: 23
  },
  {
    label: 'Mississippi',
    value: 24
  },
  {
    label: 'Missouri',
    value: 25
  },
  {
    label: 'Montana',
    value: 26
  },
  {
    label: 'Nebraska',
    value: 27
  },
  {
    label: 'Nevada',
    value: 28
  },
  {
    label: 'New Hampshire',
    value: 29
  },
  {
    label: 'New Jersey',
    value: 30
  },
  {
    label: 'New Mexico',
    value: 31
  },
  {
    label: 'New York',
    value: 32
  },
  {
    label: 'North Carolina',
    value: 33
  },
  {
    label: 'North Dakota',
    value: 34
  },
  {
    label: 'Ohio',
    value: 35
  },
  {
    label: 'Oklahoma',
    value: 36
  },
  {
    label: 'Oregon',
    value: 37
  },
  {
    label: 'Pennsylvania',
    value: 38
  },
  {
    label: 'Rhode Island',
    value: 39
  },
  {
    label: 'South Carolina',
    value: 40
  },
  {
    label: 'South Dakota',
    value: 41
  },
  {
    label: 'Tennessee',
    value: 42
  },
  {
    label: 'Texas',
    value: 43
  },
  {
    label: 'Utah',
    value: 44
  },
  {
    label: 'Vermont',
    value: 45
  },
  {
    label: 'Virginia',
    value: 46
  },
  {
    label: 'Washington',
    value: 47
  },
  {
    label: 'West Virginia',
    value: 48
  },
  {
    label: 'Wisconsin',
    value: 49
  },
  {
    label: 'Wyoming',
    value: 50
  }
]

// Pick StatusId Constants
export const PICK_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  DRAFT: 3,
  READY_TO_PICK: 4,
  IN_PROGRESS: 5,
  DONE_PICKING: 6,
  COMPLETED: 7,
  INCOMPLETE: 8,
  CANCELLED: 9,
  REQUESTED: 10
}

export const source = {
  0: 'Desktop Transfer',
  1: 'Mobile',
  2: 'Instant',
  3: 'M2M',
  4: 'API',
  5: 'Android',
  6: 'Html',
  7: 'iOS Field',
  8: 'Warehouse',
  9: 'Api-Public',
  10: 'Integration',
  11: 'Cloud',
  12: 'LoginService',
  13: 'Equipment Usage Log'
}

export const trackingSourceTypeId = {
  1: 'Count',
  2: 'InventoryManager',
  3: 'MobileCameraScan',
  4: 'MobileBarcodeScan',
  5: 'MobileRfIdScan',
  6: 'Picked',
  7: 'Reinstatement',
  8: 'Retirement',
  9: 'Transfer',
  10: 'WorkOrder',
  11: 'BleTracking'
}

export const reportOutFormat = {
  PRINT: 3,
  PDF: 4,
  CSV: 15
}

export const MONTH_DAYS = [31,
  isLeapYear(new Date().getFullYear) ? 29 : 28,
  31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const dataLoaderObject = {
  STOCK_LOCATION_DATA: 15,
  QUANTITY_TOOL_INVENTORY: 20,
  MATERIAL_INVENTORY: 21,
  SERVICE_HISTORY: 23,
  QUANTITY_TOOLS: 24,
  WORK_ORDERS: 25,
  WORK_ORDER_CHARGES: 26,
  MISC_CHARGES: 27,
  MATERIALS: 28
}

const JANUARY = 1
const FEBRUARY = 2
const MARCH = 4
const APRIL = 8
const MAY = 16
const JUNE = 32
const JULY = 64
const AUGUST = 128
const SEPTEMBER = 256
const OCTOBER = 512
const NOVEMBER = 1024
const DECEMBER = 2048

export default {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  WEEKENDDAY,
  WEEKDAY,
  ALLDAYS,
  STATE,
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER
}
