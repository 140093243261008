<template>
  <div :class="['middle-box text-center loginscreen animated fadeInDown', getLoginDivClass()]">
    <div>
      <div>
        <h1 class="logo-name">{{brandName}}</h1>
      </div>
      <h3>{{$t(brandTitle)}}</h3>
      <div :class="[getFormClass()]">
      <form v-if="isShowUsername" class="m-t" role="form" method="post" @submit="onSubmitUsername">
        <div class="form-group">
          <input
            autofocus
            type="text"
            name="email"
            v-model="email"
            class="form-control"
            :placeholder="$t(brandEmail)"
            required=""
            @keydown="onUserChange()"
            autocomplete="off">
        </div>
        <div class="form-group row">
                <div class="col-lg-12 form-group checkbox-form">
                  <input
                    type="checkbox"
                    name="rememberMe"
                    v-model="rememberMe"
                    id="rememberMe"
                  />
                  <label for="rememberMe" class="checkbox-holder"></label>
                  <label for="rememberMe" class="pl-2">{{
                    $t("authorization.rememberMe")
                  }}</label>
                </div>
        </div>
        <div v-if="isUserError">
          <p style='color: red;'>{{$t('authorization.msg')}}</p>
        </div>
        <button type="submit" class="btn btn-primary block full-width m-b" name="next">
          {{$t('authorization.next')}}
          <span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </form>
      <form v-if="isShowPassword" class="m-t" role="form" method="post" @submit="onSubmitPassword">
        <div class="form-group">
          <input type="text" name="username" id="username" :value="email">
          <input
            ref="passwordInput"
            type="password"
            name="password"
            v-model="password"
            class="form-control"
            :placeholder="$t(brandPass)"
            required=""
            @keydown="onPasswordChange()"
            autocomplete="off">
        </div>
        <div v-if="isPasswordError" class="error-message">
          <p style='color: red;'>{{$t('authorization.msg')}}</p>
        </div>
        <button type="submit" class="btn btn-primary block full-width m-b" name="login">
          {{$t('authorization.login')}}
          <span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button type="submit" class="btn close-btn block full-width m-b" @click.prevent="goBackToUsername">
          {{$t('buttons.back.default')}}
        </button>
      </form>
      </div>
      <a href="https://cloud.east.toolwatch.net/forgotpassword/" target="blank">
        {{$t('buttons.forgotPassword')}}
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { clearLocalStorage } from '../helpers/utils.js'
export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      processing: false,
      isUserError: false,
      isPasswordError: false,
      isShowUsername: true,
      rememberMe: false,
      isShowPassword: false
    }
  },
  methods: {
    async onSubmitUsername (e) {
      this.processing = true
      e.preventDefault()

      const isImpersonating = this.email.split('|').length > 1
      if (isImpersonating) {
        this.isShowUsername = false
        this.isShowPassword = true
        this.processing = false
        return
      }

      const params = new URLSearchParams()
      params.append('username', this.email.replace(/'/g, '\\\''))

      const headerConfig = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }
      try {
        const { status, data } = await axios.post('/api/getIdentityProviderInformation', params, headerConfig)
        if (status !== 201) {
          this.isUserError = true
          this.processing = false
          return
        }

        if (this.rememberMe === true) {
          this.setUsernameCookie()
        } else {
          this.clearUsernameCookie()
        }
        if (!data.samlRedirectUrl) {
          this.isShowUsername = false
          this.isShowPassword = true
          this.processing = false
          return
        }
        document.cookie = `tw.cloud.redirectUrl=${this.$route.query.redirect_url}; path=/; Secure; SameSite=None`

        delete this.$route.query.redirect_url
        const idpUrl = data.samlRedirectUrl
        window.location.href = idpUrl
      } catch (error) {
        this.processing = false
        this.isUserError = true
      }
    },
    async onSubmitPassword (e) {
      this.processing = true
      e.preventDefault()
      const params = new URLSearchParams()
      params.append('email', this.email)
      params.append('pass', this.password)

      const headerConfig = {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      }
      try {
        const { status } = await axios.post('/api/login', params, headerConfig)
        if (status !== 201) {
          this.isPasswordError = true
          this.processing = false
          return
        }
        // Clear local storage here.
        // It'll be hydrated during authentication check
        clearLocalStorage()
        this.$store.commit('setFLogin', true)
        delete this.$route.query.isLegacyLogin
        // to get the redirect urls
        let redirectUrl = this.$route.query.redirect_url
        delete this.$route.query.redirect_url
        if (redirectUrl) {
          const query = Object.entries(this.$route.query)
          query.forEach(x => {
            redirectUrl += `&${x[0]}=${x[1]}`
          })
          await this.$router.push(redirectUrl)
        } else {
          await this.$router.push('/')
        }
      } catch (error) {
        this.processing = false
        this.isPasswordError = true
      }
    },
    goBackToUsername () {
      this.password = ''
      this.isPasswordError = false
      this.isShowUsername = true
      this.isShowPassword = false
    },
    onUserChange () {
      this.isUserError = false
    },
    onPasswordChange () {
      this.isPasswordError = false
    },
    getUsernameCookie () {
      const username = this.$cookies.get('cloud_username')
      if (username) {
        this.email = username
        this.rememberMe = true
      }
    },
    setUsernameCookie () {
      this.$cookies.set('cloud_username', this.email, Infinity, null, null, true, 'None')
    },
    clearUsernameCookie: function () {
      if (this.$cookies.isKey('cloud_username') === true) {
        this.$cookies.remove('cloud_username')
      }
    },
    getFormClass () {
      return process.env.VUE_APP_BRAND === 'ALIGN' ? 'form-div' : ''
    },
    getLoginDivClass () {
      return process.env.VUE_APP_BRAND === 'ALIGN' ? '' : 'login-box'
    }
  },
  computed: {
    brandTitle () {
      if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
        return 'authorization.titleTooLops'
      } else if (process.env.VUE_APP_BRAND === 'ALIGN') {
        return 'authorization.titleAlign'
      } else {
        return 'authorization.title'
      }
    },
    brandName () {
      if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
        return 'TO'
      } else if (process.env.VUE_APP_BRAND === 'ALIGN') {
        return 'ALIGN'
      } else {
        return 'TW'
      }
    },
    brandEmail () {
      if (process.env.VUE_APP_BRAND === 'ALIGN') {
        return 'authorization.emailAlign'
      }
      return 'authorization.email'
    },
    brandPass () {
      if (process.env.VUE_APP_BRAND === 'ALIGN') {
        return 'authorization.pwdAlign'
      }
      return 'authorization.pwd'
    }
  },
  created () {
    if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
      document.documentElement.style.setProperty('--primary-color', '#00DC00')
      document.documentElement.style.setProperty('--secondary-color', '#00EB00')
    } else if (process.env.VUE_APP_BRAND === 'ALIGN') {
      document.documentElement.style.setProperty('--primary-color', '#4c84fc')
      document.documentElement.style.setProperty('--secondary-color', '#4c84fc')
    } else {
      document.documentElement.style.setProperty('--primary-color', '#d29735')
      document.documentElement.style.setProperty('--secondary-color', '#D09433')
    }
  },
  watch: {
    isShowPassword (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$refs.passwordInput.focus()
        }, 10)
      }
    }
  },
  mounted: function () {
    // Redirect user if they are hitting old url
    if (window.location.hostname === 'cloud.toolwatch.com' && !this.$route.query.isLegacyLogin) {
      window.location.href = window.location.href.replace('cloud.toolwatch.com', 'cloud.aligntech.co')
      return
    }
    localStorage.setItem('isPlatformLogin', 'false')
    this.getUsernameCookie()
  }
}
</script>
<style scoped>
  .btn.close-btn {
    background: transparent;
    color: #495760;
    border: 2px solid #DBDBDA;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .form-group.checkbox-form .checkbox-holder:before {
    top: -4px;
  }
  #username {
    position: absolute;
    left: -9999px;
  }
</style>
