import { TXT_MAX_LENGTH, TXT_MIN_LENGTH } from '../../helpers/const.js'

const validations = {
  required: '{attribute} is required',
  minLength: `{attribute} must be at least ${TXT_MIN_LENGTH} characters`,
  maxLength: `{attribute} must be at most ${TXT_MAX_LENGTH} characters`,
  pwdMinLength: '{attribute} must be at least {min} characters',
  sameAsPassword: 'Passwords must match',
  numeric: '{attribute} must be number',
  phone: '{attribute} is not valid',
  email: '{attribute} is not valid',
  url: '{attribute} is not valid',
  fax: '{attribute} is not valid',
  zip: '{attribute} is not valid',
  isDuplicate: '{attribute} has been already used',
  selectCategory: 'Select a category',
  timeValid: '{attribute} is not valid',
  selectType: 'Select a type',
  numberValid: 'Number is invalid. Choose another one',
  isValidUrl: 'URL is invalid',
  endDate: '{attribute} is not valid',
  startDate: '{attribute} is not valid',
  isValidEmail: '{attribute} is not valid',
  differentValues: '{attribute} should be different from existing value',
  noDuplicateRoleNames: 'That role name already exists',
  inactiveDate: '{attribute} is not valid',
  activeWorkflows: '{attribute} is required for active workflows.'
}

export default validations
