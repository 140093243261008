const message = {
  success: {
    setting: {
      imageUpload: 'Your new logo has been uploaded',
      settingSaved: 'Settings Saved'
    },
    catalog: {
      imageUpload: 'Model Image has been uploaded'
    },
    shipSaving: 'Shipping info has been saved',
    cart: {
      add: 'Item added to the Cart',
      remove: 'Item removed from Cart',
      update: 'Item updated on Cart'
    },
    ticket: {
      add: 'Item added to the ticket',
      remove: 'Item removed from ticket',
      update: 'Item updated on ticket'
    },
    blesensors: {
      save: 'Gateway successfully updated'
    },
    checkout: 'Successfully checked out',
    save: 'Changes saved successfully',
    edit: 'Successfully Edited',
    delete: 'Successfully Deleted',
    create: 'Successfully Created',
    copy: 'Successfully Copied',
    add: 'Successfully added',
    merge: 'Successfully Merged',
    remove: 'Successfully removed',
    process: 'Successfully processed',
    adjustmentsPost: 'Successfully adjustments posted!',
    restore: 'Successfully restored',
    defaultSave: 'Successfully Saved',
    reinstate: 'Successfully reinstated',
    attachmentReplacement: 'Attachment replaced successfully.',
    attachmentAdded: 'Attachment added successfully.',
    download: '{filename} downloaded successfully',
    move: 'Successfully moved',
    workorderRowsLimit: 'You have reached a maximum limit of {formattedCount} items. Please refine your search criteria to see more items.'
  },
  error: {
    setting: {
      imageUpload: 'Logo Upload failed, Please try again',
      settingSaved: 'Save Settings failed. Please try again',
      invalidFormat: 'Unsupported file format',
      imageSize: 'File size should not be greater than 50kb'
    },
    shipSaving: 'Save shipping info failed. Please try again',
    cart: {
      add: 'Add Item failed, Please try again',
      remove: 'Remove Item failed, Please try again',
      update: 'Update Item failed, Please try again'
    },
    blesensors: {
      save: 'Save Wireless Gateway failed. Please try again',
      maxEditRows: 'Can only edit one Gateway at a time',
      macAddressRequired: 'Mac Address is required'
    },
    customReport: {
      delete: 'Custom Report can not be deleted. It is a dependency for another resource.'
    },
    catalog: {
      imageUpload: 'Model Image Upload failed, Please try again',
      corruptFileMessage: 'The file is corrupted and unreadable',
      corruptText: 'Parameter is not valid.'
    },
    save: 'Failed to save changes',
    copy: 'Failed to copy',
    remove: 'Failed to remove',
    labelDuplication: 'Duplicate Label Name',
    emailDuplication: '*This email format is invalid or already used.',
    validate: {
      passwordLength: 'Password must be at least 8 characters',
      confirmPassword: 'Passwords must match!'
    },
    sameAssignRequiredTitle: 'Same assignment required',
    sameAssignRequiredMsg: 'All items on a Count Ticket must be assigned to the same location or person.',
    roolLocationNotDeleteMsg: 'You can not delete the root stock location',
    itemsAssignedToLocationMsg: 'This location can not be deleted, there are {0} of items assigned. Please update the assigned items or retire.',
    ownerAssignedToLocationMsg: 'This location can not be deleted, there are {0} of items owned by this location. Please update the owner of the items.',
    activeItemAssignedToLocationMsg: 'This location can not be made inactive or deleted, because there are {0} item(s) assigned.',
    activeItemAssignedToLocationSecondMsg: 'Update the assignment of the item(s) to another location.',
    activeOwnerAssignedToLocationMsg: 'This location can not be made inactive or deleted, because there are item(s) owned by this location.',
    activeOwnerAssignedToLocationSecondMsg: 'Update the ownership of the item(s) to another location.',
    activeOwnerAndItemAssignedToLocationMsg: 'This location can not be made inactive or deleted, because there are {0} item(s) assigned and {1} item(s) owned by this location.',
    activeOwnerAndItemAssignedToLocationSecondMsg: 'Update the assignment and/or ownership of the item(s) to another location.',
    activeItemAssignedToEmployeeMsg: 'This employee can not be made inactive, there are {0} item(s) assigned.',
    alreadyOpenCountTicketMsg: 'A previous ticket is still open. Please save or close the existing count ticket.',
    alreadyOpenCountTicketTitle: 'Already open count ticket',
    activeEmployeeAssignedToLocationMsg: 'This location can not be made inactive or deleted, because there are {0} employee(s) assigned to the location that must be deleted/made inactive first.'
  },
  warning: {
    delete: 'Are you sure you want to delete the following item?',
    deleteTool: 'Are you sure you want to delete this tool?',
    confirm: 'Would you like to save changes to this record?',
    confirmDelete: 'Are you sure?',
    scanItemWarning: 'You must select a line or Cancel.',
    selectToProcess: 'You must select at least one line to process.',
    confirmProcess: 'Are you sure you would like to process the selected counts?',
    deletePOItem: 'Are you sure you want to delete the selected PO(s)',
    workOrderNotServicePlan: 'This work order was not created as part of a service plan.',
    editOwner: 'Cannot change owner for quantity tools. If multiple rows are selected, \nremove quantity tools from your selection.',
    processDraft: 'Cannot create a new transfer, a draft transfer already exists. Process or cancel the existing transfer before posting the inventory adjustment',
    mismatchedCountAssigments: 'Cannot post adjustments for non-unique items not counted at their assignment location',
    autoAssigntitle: 'Continue?',
    autoAssignwarningMsg: 'All wirelessly tracked assets in range of the gateway will reassigned to the new location. Do you wish to continue?',
    workflowInProgressMsg: 'Workflows that have work in progress cannot be edited or deleted. Please complete all associated task(s) and try again.',
    workflowInProgressTitle: 'Workflow In Progress',
    workflowTemplateTitle: 'Workflow Template',
    workflowTemplateMsg: 'Workflow Template can not be deleted.',
    deleteReqBrowser: 'Are you sure you want to delete the selected requisition?',
    deleteReqBrowserItem: 'Are you sure you want to delete?',
    ReqBrowserNotDeleteWarning: 'You cannot delete requisition that have been in progress or completed.'
  },
  confirm: {
    wrongAssignTitle: 'Wrong Assignment',
    wrongAssignMsg: 'This item is not asigned to the location being counted.\nWould you like to add it anyway?',
    summaryItemDeleteTitle: 'Confirm delete',
    summaryItemDeleteMsg: 'You are about to delete all counts from the Counted Items tab associated with this item. Are you sure you want to continue?'
  },
  noData: 'No Data',
  systemDashboardNoDataSelect: 'Select',
  systemDashboardToLoadData: ' to Load Data',
  noContent: 'No Content',
  noItems: 'No Items',
  noResults: 'No Results',
  sortBy: 'Sort By',
  cannotRefresh: 'Wait 1 minute before refreshing dashboard',
  results: 'No result | 1 result | {count} results',
  itemSelection: 'No item selected | 1 item selected | {count} items selected',
  intervention: {
    0: 'for',
    1: 'in category',
    2: 'for brand',
    3: 'instead'
  },
  attachmentMsg: 'There are no attachment for this tool',
  searchTools: 'Search tools',
  comingSoon: 'Coming Soon',
  mergeHint: 'Select which model will be the merge to:',
  billing: {
    0: 'Misc. Charge/Credits can\'t be put on hold.',
    1: 'Please pick the Misc. Charge or Credit Item!',
    2: 'Only Unique tools can be put on hold'
  },
  deleteTransfer: 'Do you want to continue deleting this transfer?',
  deleteWorkOrder: 'Are you sure you want to delete the selected work order?',
  importMeter: {
    label: 'Select CSV File To Import and Validate',
    warning: 'Header row requires ItemNumber, ImportValue, and Date Columns',
    importSuccess: 'Meter import was successful'
  },
  importFuelEmployees: {
    label: 'Select CSV File To Fuel Employees and Validate',
    warning: 'Header row requires ItemNumber, ImportValue, and Date Columns',
    importSuccess: 'Fuel Transaction import was successful'
  },
  selectServiceRequest: 'Please select a service request.',
  chooseTemplateType: 'Please choose the type of template to create',
  pickTicket: {
    delete: {
      title: 'Confirm Delete',
      text: 'Are you sure you want to remove this line?',
      altTitle: 'Error',
      altText: 'You can only delete these lines while a ticket is being drafted or in progress',
      altText_1: 'You can only delete these lines while a ticket is in progress'
    },
    view: {
      title: 'Please select a \'Picked Items\' line',
      text: 'Please click the \'Picked Items\' tab, then select a line to show'
    },
    warning: {
      0: 'You must first select a line to fulfill',
      1: 'Templates can only be applied while the ticket is being drafted',
      2: 'You can only add lines while the ticket is being drafted',
      3: 'You must first select a "Pick From" value from above',
      4: 'Duplicate item {itemid} was already picked',
      5: 'Cannot adjust stock for product. Please add item before adjusting stock',
      6: 'Item already exists on pick ticket.  Please adjust quantity of existing item instead of adding it again.'
    }
  },
  retired: 'RETIRED',
  numberDuplication: 'The number {number} is already in use for:',
  saveChanges: 'Do you want to save your changes?',
  serviceEventDeletion: 'Are you sure? This can not be undone.',
  attachment: {
    title: 'Delete Attachment',
    text: 'Are you sure you want to delete this attachment?'
  },
  addToTransfer: '{number} items added to the Draft Transfer - Click to View',
  addToTransferDuplicateItemMessage: '{number} items not added, already added to the Draft Transfer -Click to View',
  addToTransferMessage: '1 item added to the Draft Transfer',
  addToTransferDuplicateMessage: '{number} items not added, already added to the Draft Transfer',
  addToolToTransferMessage: '{number} items added to the Draft Transfer',
  addToTransferDuplicateItem: 'Item not added, already added to the Draft Transfer',
  noMoreData: 'No more data',
  noResult: 'No Result',
  loading: 'Loading...',
  poweredToolWatch: 'Powered by ToolWatch',
  itemCountedOnCountTicket: 'Items counted on count ticket(s)',
  transferConfirm: 'Some items are assigned to a different location. Do you want to add them to a transfer?',
  exportTemplateDeletion: 'Are you sure you want to delete the Template?',
  restrictRetireQuantitytools: 'Can not retire quantity items, remove retirement status and perform inventory adjustment'
}
export default message
