const locationMap = {
  title: 'Locations Map',
  placeHolder: 'Search location description',

  locationType: {
    title: {
      0: 'Location Type'
    },
    labels: {
      0: 'All',
      1: 'Stock',
      2: 'Non-Stock'
    }
  },
  locationPanel: {
    title: {
      0: 'Location Details'
    }
  },
  inventorySummary: {
    title: {
      0: 'Inventory Summary'
    }
  }
}

export default locationMap
