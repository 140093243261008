const rateSheetView = {
  tableHeader: {
    desktop: [
      {
        label: 'Category',
        key: 'Category',
        type: String,
        isEditable: false,
        isTool: false,
        sortable: true
      },
      {
        label: 'Item Description',
        key: 'Description',
        type: String,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Model',
        key: 'Model',
        type: String,
        isEditable: false,
        sortable: true
      },
      {
        label: 'Model ID',
        key: 'MasterNumber',
        type: Number,
        isEditable: false,
        sortable: true
      },
      {
        label: 'Do Not Charge',
        key: 'DoNotBill',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [0, 1, 2, 3, 4, -1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Base Value',
        key: 'BaseCost',
        type: Number,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Calculation \n Method',
        key: 'BillingCalculationMethodsId',
        type: Number,
        isEditable: true,
        isTool: false,
        sortable: true
      },
      {
        label: 'Hourly Usage Rate %',
        key: 'HourlyRatePercent',
        baseKey: 'HourlyRate',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Hourly Usage Rate',
        key: 'HourlyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Effective Daily \n Rate Per Hour %',
        key: 'DailyRatePercent',
        baseKey: 'DailyRate',
        perType: 0,
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Effective Daily \n Rate Per Hour',
        key: 'DailyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Daily Rate',
        key: 'DailyTotal',
        baseKey: 'DailyRate',
        perType: 0,
        type: 'perHour',
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Effective Weekly \n Rate Per Hour %',
        key: 'WeeklyRatePercent',
        baseKey: 'WeeklyRate',
        type: 'percent',
        perType: 1,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Effective Weekly \n Rate Per Hour',
        key: 'WeeklyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Weekly Rate',
        key: 'WeeklyTotal',
        baseKey: 'WeeklyRate',
        type: 'perHour',
        perType: 1,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Effective Monthly \n Rate Per Hour %',
        key: 'MonthlyRatePercent',
        baseKey: 'MonthlyRate',
        type: 'percent',
        perType: 2,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Effective Monthly \n  Rate Per Hour',
        key: 'MonthlyRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Monthly Rate',
        key: 'MonthlyTotal',
        baseKey: 'MonthlyRate',
        type: 'perHour',
        perType: 2,
        isEditable: true,
        methodIdToActivate: [4, 3, 0],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Idle Rate',
        key: 'IdleRateEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Idle Rate %',
        key: 'IdleRatePercent',
        baseKey: 'IdleRate',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Idle Rate Per Hour',
        key: 'IdleRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Maint. \n Rate Item Charge',
        key: 'MaintenanceRateEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Maint. \n Rate Job Charge',
        key: 'MaintenanceRateEnabledAtJob',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Promote to Maint. \n Rate at %',
        key: 'MaintenanceRatePromoteAtPercent',
        baseKey: 'MaintenanceRatePromoteAt',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Promote to Maint. \n Rate at $',
        key: 'MaintenanceRatePromoteAt',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Maint. Rate %',
        key: 'MaintenanceRatePercent',
        baseKey: 'MaintenanceRate',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Maint. Rate \n Per Hour',
        key: 'MaintenanceRate',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Initial Charge %',
        key: 'InitialChargePercent',
        baseKey: 'InitialCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Initial Charge',
        key: 'InitialCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Return Credit %',
        key: 'ReturnCreditPercent',
        baseKey: 'ReturnCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Return Credit',
        key: 'ReturnCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1],
        isTool: false,
        sortable: true
      },
      {
        label: 'Use Min. Charge',
        key: 'MinChargeEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Min. Charge %',
        key: 'MinimumChargePercent',
        baseKey: 'MinCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Min. Charge',
        key: 'MinCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Max \n Item Charge',
        key: 'MaxChargeEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Max \n Job Charge',
        key: 'MaxChargeEnabledAtJob',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Max \n Period Charge',
        key: 'MaxPeriodEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Max Charge %',
        key: 'MaxChargePercent',
        baseKey: 'MaxCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Max Charge',
        key: 'MaxCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Use Loss Charge',
        key: 'LossChargeEnabled',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Loss Charge %',
        key: 'LossChargePercent',
        baseKey: 'LossCharge',
        type: 'percent',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Loss Charge',
        key: 'LossCharge',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Charge Net Loss',
        key: 'LossChargeDeductFromTotal',
        type: Boolean,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: true,
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'CostCodeId',
        type: Number,
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      },
      {
        label: 'GL Charge \n Account',
        key: 'ChargeAccountNo',
        type: 'text',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      },
      {
        label: 'GL Credit \n Account',
        key: 'CreditAccountNo',
        type: 'text',
        isEditable: true,
        methodIdToActivate: [1, 4, 3, 0, 2],
        isTool: false,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'Category',
            type: String,
            isEditable: false,
            isTool: false,
            sortable: true
          },
          {
            label: 'Item Description',
            key: 'Description',
            type: String,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Model',
            key: 'Model',
            type: String,
            isEditable: false,
            sortable: true
          },
          {
            label: 'Model ID',
            key: 'MasterNumber',
            type: Number,
            isEditable: false,
            sortable: true
          },
          {
            label: 'Do Not Charge',
            key: 'DoNotBill',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [0, 1, 2, 3, 4, -1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Base Value',
            key: 'BaseCost',
            type: Number,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Calculation \n Method',
            key: 'BillingCalculationMethodsId',
            type: Number,
            isEditable: true,
            isTool: false,
            sortable: true
          },
          {
            label: 'Hourly Usage Rate %',
            key: 'HourlyRatePercent',
            baseKey: 'HourlyRate',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Hourly Usage Rate',
            key: 'HourlyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Effective Daily \n Rate Per Hour %',
            key: 'DailyRatePercent',
            baseKey: 'DailyRate',
            perType: 0,
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Effective Daily \n Rate Per Hour',
            key: 'DailyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Daily Rate',
            key: 'DailyTotal',
            baseKey: 'DailyRate',
            perType: 0,
            type: 'perHour',
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Effective Weekly \n Rate Per Hour %',
            key: 'WeeklyRatePercent',
            baseKey: 'WeeklyRate',
            type: 'percent',
            perType: 1,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Effective Weekly \n Rate Per Hour',
            key: 'WeeklyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Weekly Rate',
            key: 'WeeklyTotal',
            baseKey: 'WeeklyRate',
            type: 'perHour',
            perType: 1,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Effective Monthly \n Rate Per Hour %',
            key: 'MonthlyRatePercent',
            baseKey: 'MonthlyRate',
            type: 'percent',
            perType: 2,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Effective Monthly \n  Rate Per Hour',
            key: 'MonthlyRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Monthly Rate',
            key: 'MonthlyTotal',
            baseKey: 'MonthlyRate',
            type: 'perHour',
            perType: 2,
            isEditable: true,
            methodIdToActivate: [4, 3, 0],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Idle Rate',
            key: 'IdleRateEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Idle Rate %',
            key: 'IdleRatePercent',
            baseKey: 'IdleRate',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Idle Rate Per Hour',
            key: 'IdleRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Maint. \n Rate Item Charge',
            key: 'MaintenanceRateEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Maint. \n Rate Job Charge',
            key: 'MaintenanceRateEnabledAtJob',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Promote to Maint. \n Rate at %',
            key: 'MaintenanceRatePromoteAtPercent',
            baseKey: 'MaintenanceRatePromoteAt',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Promote to Maint. \n Rate at $',
            key: 'MaintenanceRatePromoteAt',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Maint. Rate %',
            key: 'MaintenanceRatePercent',
            baseKey: 'MaintenanceRate',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Maint. Rate \n Per Hour',
            key: 'MaintenanceRate',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Initial Charge %',
            key: 'InitialChargePercent',
            baseKey: 'InitialCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Initial Charge',
            key: 'InitialCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Return Credit %',
            key: 'ReturnCreditPercent',
            baseKey: 'ReturnCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Return Credit',
            key: 'ReturnCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1],
            isTool: false,
            sortable: true
          },
          {
            label: 'Use Min. Charge',
            key: 'MinChargeEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Min. Charge %',
            key: 'MinimumChargePercent',
            baseKey: 'MinCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Min. Charge',
            key: 'MinCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Max \n Item Charge',
            key: 'MaxChargeEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Max \n Job Charge',
            key: 'MaxChargeEnabledAtJob',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Max \n Period Charge',
            key: 'MaxPeriodEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Max Charge %',
            key: 'MaxChargePercent',
            baseKey: 'MaxCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Max Charge',
            key: 'MaxCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Use Loss Charge',
            key: 'LossChargeEnabled',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Loss Charge %',
            key: 'LossChargePercent',
            baseKey: 'LossCharge',
            type: 'percent',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Loss Charge',
            key: 'LossCharge',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Charge Net Loss',
            key: 'LossChargeDeductFromTotal',
            type: Boolean,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: true,
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'CostCodeId',
            type: Number,
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          },
          {
            label: 'GL Charge \n Account',
            key: 'ChargeAccountNo',
            type: 'text',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          },
          {
            label: 'GL Credit \n Account',
            key: 'CreditAccountNo',
            type: 'text',
            isEditable: true,
            methodIdToActivate: [1, 4, 3, 0, 2],
            isTool: false,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default rateSheetView
