<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <div>
        <h1 class="logo-name">{{brandName}}</h1>
      </div>
      <h3>{{$t(brandTitle)}}</h3>
    </div>
  </div>
</template>

<script>
import { clearLocalStorage, getPlatformLoginUrl } from '../helpers/utils.js'
export default {
  name: 'PlatformLogin',
  created () {
    if (process.env.VUE_APP_BRAND === 'TOOLOPS') {
      document.documentElement.style.setProperty('--primary-color', '#00DC00')
      document.documentElement.style.setProperty('--secondary-color', '#00EB00')
    } else if (process.env.VUE_APP_BRAND === 'ALIGN') {
      document.documentElement.style.setProperty('--primary-color', '#4c84fc')
      document.documentElement.style.setProperty('--secondary-color', '#4c84fc')
    } else {
      document.documentElement.style.setProperty('--primary-color', '#d29735')
      document.documentElement.style.setProperty('--secondary-color', '#D09433')
    }
  },
  mounted: async function () {
    const storeLoginMethod = this.$route.query.storeLoginMethod
    const isPlatformLogin = !storeLoginMethod || storeLoginMethod === 'true'
    localStorage.setItem('isPlatformLogin', isPlatformLogin)
    delete this.$route.query.storeLoginMethod
    await this.tryLogin()
  },
  methods: {
    async tryLogin () {
      const completeLogin = this.$route.query.complete_login
      delete this.$route.query.complete_login
      if (!completeLogin) {
        const redirectUrl = this.processRedirectUrl()
        window.location.href = `${this.platformUrl}/authorize?callback_uri=${this.callbackUri}/platformlogin&redirect_uri=${redirectUrl}&client_type=web&requested_fields=clusterName&brand=${this.brand}`
      } else {
        await this.completeLogin()
      }
    },
    async completeLogin () {
      // Clear local storage here.
      // It'll be hydrated during authentication check
      clearLocalStorage()
      this.$store.commit('setFLogin', true)
      // to get the redirect urls
      delete this.$route.query.isLegacyLogin
      const redirectUrl = this.processRedirectUrl()
      await this.$router.push(redirectUrl)
    },
    processRedirectUrl () {
      let redirectUrl = this.$route.query.redirect_url
      delete this.$route.query.redirect_url
      if (redirectUrl) {
        const query = Object.entries(this.$route.query)
        query.forEach(x => {
          redirectUrl += `&${x[0]}=${x[1]}`
        })
      } else {
        redirectUrl = '/'
      }
      return redirectUrl
    }
  },
  computed: {
    brandTitle () {
      if (this.brand === 'TOOLOPS') {
        return 'authorization.titleTooLops'
      } else if (this.brand === 'ALIGN') {
        return 'authorization.titleAlign'
      } else {
        return 'authorization.title'
      }
    },
    brandName () {
      if (this.brand === 'TOOLOPS') {
        return 'TO'
      } else if (this.brand === 'ALIGN') {
        return 'ALIGN'
      } else {
        return 'TW'
      }
    },
    platformUrl () {
      return getPlatformLoginUrl()
    },
    callbackUri () {
      if (window.location.origin === 'https://cloud.toolwatch.com' && !this.$route.query.isLegacyLogin) {
        return 'https://cloud.aligntech.co'
      }

      return window.location.origin
    },
    brand () {
      return process.env.VUE_APP_BRAND
    }
  }
}
</script>
<style scoped>
  .btn.close-btn {
    background: transparent;
    color: #495760;
    border: 2px solid #DBDBDA;
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .form-group.checkbox-form .checkbox-holder:before {
    top: -4px;
  }
</style>
