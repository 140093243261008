import axios from 'axios'
import { errorHandler, deepCopy, getFormatedDateString, getFormattedTimeString, setRequisitionHeader, uniq } from '../helpers/utils'

export default {
  state: () => ({
    requisitions: [],
    requisitionHeader: {},
    requisitionNumber: null,
    requisitionSearchParam: {
      stateId: 1,
      requisitionStatus: null,
      requisitionForId: null,
      assignedToId: null,
      dateCreatedStart: `${getFormatedDateString(new Date(), 0)}T00:00:00`,
      dateCreatedEnd: null,
      numberFrom: null,
      numberTo: null,
      requisitionFromId: null,
      requestedById: null
    },
    requisitionStatuses: []
  }),
  mutations: {
    addRequisition (state, item) {
      const array = [...state.requisitions, ...item]
      state.requisitions = uniq(array, x => x.LineId)
    },
    resetRequisition (state) {
      state.requisitions = []
    },
    setRequisitionHeader (state, header) {
      state.requisitionHeader = { ...header }
    },
    reRequisitionHeader (state) {
      state.requisitionHeader = {}
    },
    removeRequisitionItem (state, id) {
      state.requisitions = deepCopy(state.requisitions.filter(ele => ele.LineId != id))
    },
    setRequisitionSearchParam (state, value) {
      state.requisitionSearchParam = value
    },
    setRequisitionStatuses (state, value) {
      state.requisitionStatuses = value
    }
  },
  actions: {
    async getRequisitionHeader (context) {
      const paramsNextNumber = JSON.stringify({
        numberType: JSON.stringify(37), // requisition tickets
        requestedNumberSource: JSON.stringify(1) // System
      })
      try {
        const { data } = await axios.post('/api/core/NextNumberService/GetNextNumber', paramsNextNumber)
        context.commit('setRequisitionHeader', {
          ...setRequisitionHeader(data.Body.Number)
        })
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    }
  },
  getters: {
    requisitions (state) {
      return state.requisitions
    },
    requisitionHeader (state) {
      return state.requisitionHeader
    },
    getRequisitionSearchParam (state) {
      return state.requisitionSearchParam
    },
    requisitionStatuses (state) {
      return state.requisitionStatuses
    }
  }
}
