<template>
  <v-select
    v-model="selectedOrg"
    label="Description"
    class="req-org-v-select"
    :options="orgLists"
    :clearable="false"
    @input="$emit('onOrgSelected', $event), $emit('resetData', true)"
    placeholder=" "
  ></v-select>
</template>

<script>
import vSelect from 'vue-select'
import { setProcoreShipTo } from '../../helpers/procore.js'
export default {
  name: 'ReqOrgList',
  components: {
    vSelect
  },
  data () {
    return {
      orgLists: [],
      selectedOrg: null
    }
  },
  async created () {
    let orgLists = []

    if (this.platformNavigation) {
      if (!this.orgLocations.length) {
        await this.$store.dispatch('loadOrgLocations')
      }
      orgLists = [].concat(this.orgLocations)
        .map(({ twEntityId: Id, description: Description, parentId: ParentId, ...rest }) => ({
          Id, Description, ParentId, ...rest
        }))
    } else {
      if (!this.locations.length) {
        await this.$store.dispatch('loadStockLocations')
      }
      orgLists = [].concat(this.locations)
    }

    function createLevelArray (data, ParentId = null) {
      const idToNode = {}
      const rootNodes = []

      data.forEach(item => {
        idToNode[item.Id] = item
      })

      data.forEach(item => {
        const parent = idToNode[item.ParentId]
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(item)
        } else {
          rootNodes.push(item)
        }
      })
      return rootNodes
    }

    const indentedOrgList = []
    function addIndentation (array, level) {
      level += 1
      array.forEach(item => {
        const dash = level === 0 ? '' : ' - '
        item.Description = '\xA0\xA0'.repeat(level) + dash + item.Description
        indentedOrgList.push({
          Id: item.Id,
          Description: item.Description,
          IsStockpoint: item.IsStockpoint,
          StockpointId: item.StockpointId,
          ParentId: item.ParentId
        })
        if (item.children && item.children.length !== 0) {
          addIndentation(item.children, level)
        }
      })
    }

    const nestedArray = createLevelArray(orgLists, null)
    addIndentation(nestedArray, -1)

    const locationId = this.$store.getters.getStockpointId
    this.orgLists = indentedOrgList
    this.selectedOrg = this.orgLists.find(
      (list) => list.Id === locationId
    )
  },
  mounted: function () {
    if (process.env.VUE_APP_BRAND === 'ALIGN') {
      document.documentElement.style.setProperty('--navbar-dropdown-bg-color', '#20333c')
      document.documentElement.style.setProperty('--navbar-dropdown-text-color', '#fff')
      document.documentElement.style.setProperty('--navbar-dropdown-arrow-color', '#fff')
    } else {
      document.documentElement.style.setProperty('--navbar-dropdown-bg-color', '#fff')
      document.documentElement.style.setProperty('--navbar-dropdown-text-color', '#000')
      document.documentElement.style.setProperty('--navbar-dropdown-arrow-color', '#9d9d9d')
    }
  },
  computed: {
    locations () {
      return this.$store.getters.getLocations
    },
    orgLocations () {
      return this.$store.getters.getOrgLocations
    },
    cart () {
      const cart = this.$store.getters.cart
      setProcoreShipTo(cart)
      return cart
    },
    platformNavigation () {
      return this.$store.getters.showPlatformNavigation
    }
  }
}
</script>
<style scoped>
  .vs__selected-options > span.vs__selected {
    color: #fff !important;
  }
</style>
