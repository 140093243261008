const requisitionBrowser = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Requisition No',
        type: Number,
        key: 'Number',
        sortable: true
      },
      {
        label: 'Due',
        type: Date,
        key: 'RequestedDueDate',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'Request For',
        type: String,
        key: 'RequestedTo',
        sortable: true
      },
      {
        label: 'Request From',
        key: 'RequestedFrom',
        sortable: true
      },
      {
        label: 'Requested By',
        key: 'RequestedBy',
        sortable: true
      },
      {
        label: 'Created',
        type: Date,
        key: 'CreatedOn',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'State',
        type: String,
        key: 'StateType',
        sortable: true
      },
      {
        label: 'Status',
        type: String,
        key: 'StatusValue',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Requisition No',
            type: Number,
            key: 'Number',
            sortable: true
          },
          {
            label: 'Due',
            type: Date,
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Request For',
            type: String,
            key: 'RequestedTo',
            sortable: true
          },
          {
            label: 'Request From',
            key: 'RequestedFrom',
            sortable: true
          },
          {
            label: 'Requested By',
            key: 'RequestedBy',
            sortable: true
          },
          {
            label: 'Created',
            type: Date,
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'State',
            type: String,
            key: 'StateType',
            sortable: true
          },
          {
            label: 'Status',
            type: String,
            key: 'StatusValue',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default requisitionBrowser
