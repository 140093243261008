<template>
  <div style="display: inline;">
    <template
      v-if="allPermitted"
    >
      <slot></slot>
    </template>
    <template
      v-else
    >
      <button
        class="btn btn-warning ml-2 m-auto"
        type="button"
        :class="cart ? 'cart-btn no-stock': ''"
        disabled
      >
        <span>
          <i
            class="material-icons float-left"
            style="cursor: default;"
            v-tooltip:bottom="$t('buttons.twButtonTooltip', { buttonText: buttonText, permission: getMissingPermissions })"
          >
            lock
          </i>
          {{buttonText}}
        </span>
      </button>
    </template>
  </div>
</template>
<script>
// THIS COMPONENT IS OBSOLETE. PLEASE USE TWButtonV2 INSTEAD.
// IF NO MORE USES OF THIS COMPONENT EXIST, DELETE IT
import $ from 'jquery'

export default {
  name: 'TWButton',
  props: {
    permissions: {
      type: Array,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    cart: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    userPermissions () {
      return this.$store.getters.userPermission
    },
    allPermitted () {
      if (!this.permissions.length) return true
      return !this.permissions.some(permission => !this.userPermissions.includes(permission))
    },
    getMissingPermissions () {
      const missingPermissions = []
      this.permissions.forEach(item => {
        if (!this.userPermissions.includes(item)) {
          missingPermissions.push(item)
        }
      })

      return missingPermissions.join(', ')
    }
  },
  directives: {
    tooltip: {
      inserted (el, binding) {
        $(el).tooltip({
          title: binding.value,
          placement: binding.arg,
          trigger: 'hover',
          delay: 200
        })
      }
    }
  }
}
</script>
<style>
.material-icons {
  font-size: 20px;
  margin-right: 3px;
}
.tooltip {
  z-index: 90001!important;
}
</style>
