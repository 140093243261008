const safetyPlan = {
  title: 'Site Safety Plans',
  addSiteSafetyPlan: 'Add Site Safety Plan',
  viewSiteSafetyPlanDocument: 'View Site Safety Plan Document',
  dateValidationMessage: 'End Date must be greater than the Start Date.',
  frequencyDaysValidationMessage: 'Please select at least one checkbox for Day(s)',
  frequencyMonthsValidationMessage: 'Please select at least one checkbox for Month(s)',
  deleteConfirmation: 'Are you sure you want to delete the Site Safety Plan?',
  header: {
    title: 'Create Site Safety Plan',
    editTitle: 'Edit Site Safety Plan',
    labels: {
      0: 'Clone Existing Plan',
      1: 'Plan Name',
      2: 'Plan ID',
      3: 'Description',
      4: 'Project No.'
    },
    buttons: {
      attachBtnText: 'Attach Files'
    }
  },
  tabs: {
    0: 'General Info',
    1: 'Safety Requirements',
    2: 'Hazards & PPE',
    3: 'Certifications',
    4: 'Stakeholders & Contractors',
    5: 'Record Info'
  },
  dropdownActions: [
    {
      title: 'Edit',
      actionName: 'editSafetyPlan',
      permissions: []
    },
    {
      title: 'Delete',
      actionName: 'confirmDeleteSafetyPlan',
      permissions: []
    }
  ],
  safetyRequirementspanels: {
    inspectionRequirements: 'Inspection Requirements',
    trainingRequirements: 'Training Requirements',
    incidentRequirements: 'Incident Requirements',
    observationRequirements: 'Observation Requirements',
    jsaRequirements: 'JSA Requirements',
    safetyScanRequirements: 'Safety Scan Requirements',
    formsRequirements: 'Forms Requirements'
  },
  StakeholdersContractors: {
    stakeholders: 'Stakeholders',
    contractors: 'Contractors'
  },
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'SafetyPlanHeaderId',
        type: 'check'
      },
      {
        label: 'Plan ID',
        key: 'PlanId',
        sortable: true
      },
      {
        label: 'Plan Name',
        key: 'PlanName',
        sortable: true
      },
      {
        label: 'Assigned Job Location',
        key: 'AssignedJobLocation',
        sortable: true
      },
      {
        label: 'Start Date',
        key: 'ProjectStartDate',
        sortable: true,
        type: Date
      },
      {
        label: 'End Date',
        key: 'ProjectEndDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Status',
        key: 'Status',
        sortable: true
      },
      {
        label: 'State',
        key: 'State',
        sortable: true
      },
      {
        label: 'Project No.',
        key: 'ProjectNumber',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'SafetyPlanHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Plan ID',
            key: 'PlanId',
            sortable: true
          },
          {
            label: 'Plan Name',
            key: 'PlanName',
            sortable: true
          },
          {
            label: 'Assigned Job Location',
            key: 'AssignedJobLocation',
            sortable: true
          },
          {
            label: 'Start Date',
            key: 'ProjectStartDate',
            sortable: true,
            type: Date
          },
          {
            label: 'End Date',
            key: 'ProjectEndDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: true
          },
          {
            label: 'State',
            key: 'State',
            sortable: true
          },
          {
            label: 'Project No.',
            key: 'ProjectNumber',
            sortable: true
          }
        ]
      }
    ]
  },
  addSafetyPlanInspection: 'Add Inspection',
  inspectionRequirements: {
    searchPlaceHolder: 'Search'
  },
  trainingRequirements: {
    buttons: {
      addTraining: 'Add Training'
    },
    dropdownActions: [
      {
        title: 'Edit',
        actionName: 'editTraining',
        permissions: []
      },
      {
        title: 'Delete',
        actionName: 'confirmDeleteTraining',
        permissions: []
      },
      {
        title: 'Clone',
        actionName: 'cloneTraining',
        permissions: []
      }
    ],
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyPlanTrainingId',
          type: 'check'
        },
        {
          label: 'Name',
          key: 'TrainingName',
          sortable: true
        },
        {
          label: 'Frequency',
          key: 'FrequencyName',
          sortable: true
        },
        {
          label: 'Start Date',
          key: 'StartDate',
          sortable: true,
          type: Date
        },
        {
          label: 'End Date',
          key: 'EndDate',
          sortable: true,
          type: Date
        },
        {
          label: 'Employees Assigned',
          key: 'UserAssignedNames',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'SafetyPlanTrainingId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Name',
              key: 'TrainingName',
              sortable: true
            },
            {
              label: 'Frequency',
              key: 'FrequencyName',
              sortable: true
            },
            {
              label: 'Start Date',
              key: 'StartDate',
              sortable: true,
              type: Date
            },
            {
              label: 'End Date',
              key: 'EndDate',
              sortable: true,
              type: Date
            },
            {
              label: 'Employees Assigned',
              key: 'UserAssignedNames',
              sortable: true
            }
          ]
        }
      ]
    },
    toolboxTalkExamCourse: {
      paragraph1: 'Training Review Exams are generated for email or text message and delivered to each of the selected users who have valid email and/or cell phones entered. Upon completing this page, exam messages will be created with a link to the Toolbox Talk Exam page and sent or scheduled to be sent to each user. Delivery method is determined by the training users settings (i.e. email or cell phone).',
      paragraph2: 'Text messages will only be sent between the hours of 6am and 10pm central time. To check the status of the Review Exam simply edit this Training Event to view the list of users appearing in the Exam Recipients section below. Each listing will contain the training users name along with their exam link, number of attempts and score.'
    },
    trainingDetail: {
      title: 'Training Detail',
      SourceRequired: 'Source is required',
      CategoryRequired: 'Category is required',
      ToolboxTalkRequired: 'Toolbox Talk is required',
      ConfirmationRequired: 'Training Confirmation is required',
      TrainersAssignedRequired: 'Trainers Assigned is required',
      labels: {
        0: 'Training Name',
        1: 'Course Type',
        2: 'Frequency',
        3: 'Day(s)',
        4: 'Month(s)',
        5: 'Start & End Date',
        6: 'Employees Assigned',
        7: 'Reporting Instructions',
        8: 'Select All',
        9: 'Unselect All',
        11: 'Source',
        12: 'Category',
        13: 'Toolbox Talk',
        14: 'Training Confirmation',
        15: 'Trainers Assigned'
      }
    },
    deleteConfirmation: 'Are you sure you want to delete the Training Requirement(s)?',
    TrainingConfirmationParagraph1: `Training Confirmations are generated for email or text message 
    and delivered to each of the selected users who have valid email and/or cell phones entered.
    Upon completing this page, messages will be created with a link to the confirmation page and 
    sent or scheduled to be sent to each user.`,
    TrainingConfirmationParagraph2: `Delivery method is determined by the training users 
    settings (i.e. email or cell phone).  Text messages will only be sent between the hours of 6am
    and 10pm central time. To check the status of the confirmation simply edit this Training 
    Event to view the list of users appearing in the Recipients section below.  Each listing will contain 
    the training users name along with their exam link, number of attempts and score.`
  },
  stakeholders: {
    title: 'Stakeholder Detail',
    buttons: {
      addStakeholder: 'Add Stakeholder'
    },
    dropdownActions: [
      {
        title: 'Edit',
        actionName: 'editStakeHolder',
        permissions: []
      },
      {
        title: 'Delete',
        actionName: 'confirmDeleteStakeHolder',
        permissions: []
      }
    ],
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyplanStakeHolderId',
          type: 'check'
        },
        {
          label: 'Name',
          key: 'Name',
          sortable: true
        },
        {
          label: 'Title/ Responsibilities',
          key: 'TaskResponsibility',
          sortable: true
        },
        {
          label: 'Email',
          key: 'Email',
          sortable: true
        },
        {
          label: 'Phone',
          key: 'Phone',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'safetyplanStakeHolderId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Name',
              key: 'Name',
              sortable: true
            },
            {
              label: 'Title/ Responsibilities',
              key: 'TaskResponsibility',
              sortable: true
            },
            {
              label: 'Email',
              key: 'Email',
              sortable: true
            },
            {
              label: 'Phone',
              key: 'Phone',
              sortable: true
            }
          ]
        }
      ]
    },
    labels: {
      0: 'Stakeholder',
      1: 'Title/ Responsibilities',
      2: 'Email',
      3: 'Phone'
    },
    deleteConfirmation: 'Are you sure you want to delete the Stakeholder?'
  },
  jsaRequirements: {
    buttons: {
      addJsa: 'Add JSA'
    },
    dropdownActions: [
      {
        title: 'Edit',
        actionName: 'editSafetyPlanJsa',
        permissions: []
      },
      {
        title: 'Delete',
        actionName: 'confirmDeleteSafetyPlanJsa',
        permissions: []
      },
      {
        title: 'Clone',
        actionName: 'cloneSafetyPlanJsa',
        permissions: []
      }
    ],
    JSADetail: {
      title: 'JSA Detail',
      labels: {
        0: 'JSA Name',
        1: 'JSA Bank Template',
        5: 'Start & End Date',
        6: 'Users Assigned',
        7: 'Reporting Instructions'
      },
      dateValidationMessage: 'End Date must be greater than the Start Date.',
      deleteConfirmation: 'Are you sure you want to delete the JSA Requirement(s)?'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyPlanJSAId',
          type: 'check'
        },
        {
          label: 'Name',
          key: 'JsaName',
          sortable: true
        },
        {
          label: 'JSA Bank Template',
          key: 'BanKTemplateIdDesc',
          sortable: true
        },
        {
          label: 'Frequency',
          key: 'PatternType',
          sortable: true
        },
        {
          label: 'Start Date',
          key: 'StartDate',
          sortable: true,
          type: Date
        },
        {
          label: 'End Date',
          key: 'EndDate',
          sortable: true,
          type: Date
        },
        {
          label: 'User Assigned',
          key: 'UserAssigned',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'SafetyPlanJSAId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Name',
              key: 'JsaName',
              sortable: true
            },
            {
              label: 'JSA Bank Template',
              key: 'BanKTemplateIdDesc',
              sortable: true
            },
            {
              label: 'Frequency',
              key: 'PatternType',
              sortable: true
            },
            {
              label: 'Start date',
              key: 'StartDate',
              sortable: true,
              type: Date
            },
            {
              label: 'End date',
              key: 'EndDate',
              sortable: true,
              type: Date
            },
            {
              label: 'User Assigned',
              key: 'UserAssigned',
              sortable: true
            }
          ]
        }
      ]
    }
  },
  hazardsPPE: {
    buttons: {
      addHazard: 'Add Hazard'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'HazardId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'HazardNumber',
          sortable: true
        },
        {
          label: 'Hazard',
          key: 'Hazard',
          sortable: true
        },
        {
          label: 'Description',
          key: 'HazardDescription',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'HazardId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'No.',
              key: 'HazardNumber',
              sortable: true
            },
            {
              label: 'Hazard',
              key: 'Hazard',
              sortable: true
            },
            {
              label: 'Description',
              key: 'HazardDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    hazardDetail: {
      title: 'Create Hazard',
      labels: {
        0: 'Hazard No.',
        1: 'Hazard',
        2: 'Description',
        3: 'IsGeneric'
      }
    },
    hazardsPPEPanels: {
      hazardsOnSite: 'Hazards On-Site',
      ppeRequirements: 'PPE Requirements'
    }
  },
  safetyPlanObservation: {
    labels: {
      0: 'Enforce Observation Requirements',
      1: 'Reporting Instructions',
      2: 'Submission Frequency'
    },
    message: {
      0: 'Please select at least one checkbox for Day(s) under Observation Requirements.',
      1: 'Please select at least one checkbox for Month(s) under Observation Requirements.',
      3: 'End Date must be greater than the Start Date under Observation Requirements.'
    }
  },
  safetyIncident: {
    labels: {
      0: 'Enforce Incident Requirements',
      1: 'Reporting Instructions',
      2: 'Medical Services & First Aid Administration'
    }
  },
  ppeRequirements: {
    buttons: {
      addPPE: 'Add PPE'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'SafetyplanPPEId',
          type: 'check'
        },
        {
          label: 'No.',
          key: 'PPENumber',
          sortable: true
        },
        {
          label: 'Personal Protective Equipment',
          key: 'PPE',
          sortable: true
        },
        {
          label: 'Description',
          key: 'PPEDescription',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'SafetyplanPPEId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'No.',
              key: 'PPENumber',
              sortable: true
            },
            {
              label: 'Personal Protective Equipment',
              key: 'PPE',
              sortable: true
            },
            {
              label: 'Description',
              key: 'PPEDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    ppeDetail: {
      title: 'Create Personal Protective Equipment',
      labels: {
        0: 'PPE No.',
        1: 'PPE',
        2: 'Description'
      }
    }
  },
  recordInfo: {
    title: 'Record Information',
    labels: {
      0: 'Created',
      1: 'By',
      2: 'Last Modified'
    }
  },
  certifications: {
    buttons: {
      addCertification: 'Add Certification'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'CertificationId',
          type: 'check'
        },
        {
          label: 'Code',
          key: 'CertificationNumber',
          sortable: true
        },
        {
          label: 'Description',
          key: 'Description',
          sortable: true
        },
        {
          label: 'Notes',
          key: 'Notes',
          sortable: true
        },
        {
          label: 'Renewal',
          key: 'RenewValue',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'CertificationId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Code',
              key: 'CertificationNumber',
              sortable: true
            },
            {
              label: 'Description',
              key: 'Description',
              sortable: true
            },
            {
              label: 'Notes',
              key: 'Notes',
              sortable: true
            },
            {
              label: 'Renewal',
              key: 'RenewValue',
              sortable: true
            }
          ]
        }
      ]
    }
  }
}
export default safetyPlan
