const transfer = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: '',
        key: 'Qty',
        type: 'warning'
      },
      {
        label: 'Kit',
        key: 'KitNumber',
        nested: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Item',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'All',
        key: 'isCheckIn',
        isEditable: true,
        type: Boolean,
        sortable: true,
        isSelectAllWithKey: true
      },
      {
        label: 'Out',
        key: 'QtyOut',
        type: Number,
        sortable: true
      },
      {
        label: 'In',
        key: 'Quantity',
        isEditable: true,
        type: Number,
        sortable: true
      },
      {
        label: 'Description',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'From',
        key: 'EntityId',
        nested: true,
        isEditable: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Cost Center Form',
        key: 'FromCenterId',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Status',
        key: 'StatusCodeId',
        nested: true,
        isEditable: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Meter',
        key: 'Meter',
        isEditable: true,
        sortable: true
      },
      {
        label: 'PI',
        key: 'PersonalIssue',
        nested: true,
        nestedKey: 'FromKey',
        type: Boolean,
        isEditable: true,
        sortable: true
      },
      {
        label: 'Owner',
        key: 'OwnerId',
        nested: true,
        isEditable: true,
        nestedKey: 'FromKey',
        sortable: true
      },
      {
        label: 'Cost Center To',
        key: 'ToCenterId',
        isEditable: true,
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'ToCodeId',
        isEditable: true,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'Id',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'Qty',
            type: 'warning'
          },
          {
            label: 'Kit',
            key: 'KitNumber',
            nested: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Item',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'All',
            key: 'isCheckIn',
            isEditable: true,
            type: Boolean,
            sortable: true,
            isSelectAllWithKey: true
          },
          {
            label: 'Out',
            key: 'QtyOut',
            type: Number,
            sortable: true
          },
          {
            label: 'In',
            key: 'Quantity',
            isEditable: true,
            type: Number,
            sortable: true
          },
          {
            label: 'Description',
            key: 'Description',
            type: String,
            sortable: true
          },
          {
            label: 'From',
            key: 'EntityId',
            nested: true,
            isEditable: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Cost Center Form',
            key: 'FromCenterId',
            isEditable: true,
            sortable: true
          },
          {
            label: 'Status',
            key: 'StatusCodeId',
            nested: true,
            isEditable: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Meter',
            key: 'Meter',
            isEditable: true,
            sortable: true
          },
          {
            label: 'PI',
            key: 'PersonalIssue',
            nested: true,
            nestedKey: 'FromKey',
            type: Boolean,
            isEditable: true,
            sortable: true
          },
          {
            label: 'Owner',
            key: 'OwnerId',
            nested: true,
            isEditable: true,
            nestedKey: 'FromKey',
            sortable: true
          },
          {
            label: 'Cost Center To',
            key: 'ToCenterId',
            isEditable: true,
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'ToCodeId',
            isEditable: true,
            sortable: true
          }
        ]
      }
    ]
  }
}

export default transfer
